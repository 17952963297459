import './autoComplete.css'
import { CustomEventTarget } from './customEvent'
import { debounce } from 'lodash'

function addListenerAfterMount(targetSelector, eventType, listener) {
  const element = document.querySelector(targetSelector)
  if (element) {
    element.addEventListener(eventType, listener)
    return
  }
  const observer = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      for (const addedNode of mutation.addedNodes) {
        if (addedNode instanceof Element && addedNode.matches(targetSelector)) {
          addedNode.addEventListener(eventType, listener)
          observer.disconnect() // 停止观察，避免重复添加监听器
          return
        }
      }
    }
  })

  observer.observe(document.body, { childList: true, subtree: true })
}

export class AutoComplete extends CustomEventTarget {
  constructor({ input, customRequest, apiUrl, map } = {}) {
    super()
    this.inputId = input
    this.customRequest = customRequest
    this.apiUrl = apiUrl
    this.map = map
    this.resultsDiv = null // 用于存放结果的div
    this.resultMarker = null
    this.addEventListeners()
  }

  createResultsDiv() {
    this.resultsDiv = document.createElement('div')
    this.resultsDiv.classList.add('search-results') // 添加样式类，方便CSS控制
    this.resultsDiv.style.display = 'none' // 初始隐藏结果列表
    this.inputElement.parentNode.insertBefore(this.resultsDiv, this.inputElement.nextSibling)
  }


  addEventListeners() {
    const handleInput = debounce((event) => {
      const query = event.target.value.trim()

      if (query.length === 0) {
        this.hideResults()
        return
      }

      this.fetchData(query)
        .then(data => this.displayResults(data))
          .catch(error => console.error("Error fetching data:", error))
  }, 500)
    addListenerAfterMount(`#${this.inputId}`, 'input', handleInput.bind(this))
    addListenerAfterMount(`#${this.inputId}`, 'change', handleInput.bind(this))
    // 点击页面其他地方隐藏结果列表
    document.addEventListener('click', (event) => {
      if (event.target !== this.inputElement && (this.resultsDiv && !this.resultsDiv.contains(event.target))) {
        this.hideResults()
      }
    })
  }


  async fetchData(query) {
    if (this.customRequest) {
      return this.customRequest(query)
    }
    try {
      const response = await fetch(`${this.apiUrl}?q=${query}`) // 将查询参数添加到API URL
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return await response.json()
    } catch (error) {
      console.error("Fetch error:", error)
      return [] // 返回空数组，避免后续处理出错
    }
  }



  displayResults(data) {
    this.inputElement = document.getElementById(this.inputId)
    if (!this.inputElement) {
      throw new Error(`Input element with id "${this.inputId}" not found.`)
    }

    if (!this.resultsDiv) {
      this.createResultsDiv()
    }
    this.resultsDiv.style.position = 'absolute'
    this.resultsDiv.style.top = `${this.inputElement.offsetTop + this.inputElement.offsetHeight}px`
    this.resultsDiv.style.left = `${this.inputElement.offsetLeft}px`
    this.resultsDiv.style.width = `${this.inputElement.offsetWidth}px`

    this.resultsDiv.innerHTML = '' // 清空之前的搜索结果

    if (!data.poiList || !data.poiList.pois || data.poiList.pois.length === 0) {
      this.hideResults()
      return
    }

    data.poiList.pois.forEach(item => {
      const resultItem = document.createElement('div')
      resultItem.classList.add('search-result-item') // 添加样式类，方便CSS控制

      const nameElement = document.createElement('div')
      nameElement.textContent = item.name
      const addressElement = document.createElement('div')
      addressElement.textContent = item.address ? `地址: ${item.address}` : '地址: 空'
      addressElement.style.fontSize = '0.8em' // Add smaller font size for address

      resultItem.appendChild(nameElement)
      resultItem.appendChild(addressElement)

      resultItem.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          this.inputElement.value = item.name // 点击结果项，将值填充到输入框
          this.hideResults()
          this.dispatchEvent(new Event('select', { detail: item }))
          this.displayResultOnMap(item.location)
        }
      })
      resultItem.addEventListener('click', () => {
          this.inputElement.value = item.name // 点击结果项，将值填充到输入框
          this.hideResults()
          this.dispatchEvent(new Event('select', { detail: item }))
          this.displayResultOnMap(item.location)
      })
      this.resultsDiv.appendChild(resultItem)
    })

    this.showResults()
  }

  displayResultOnMap(data) {
    if (this.resultMarker) this.resultMarker.setMap(null)
    const position = new AMap.LngLat(data[0], data[1])
    const icon = new AMap.Icon({
      size: new AMap.Size(20, 30), // 图标尺寸
      image:
        "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png", // Icon的图像
      // imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
      imageSize: new AMap.Size(20, 30) // 根据所设置的大小拉伸或压缩图片
    })

    this.resultMarker = new AMap.Marker({
      icon,
      position,
      map: this.map,
      size: new AMap.Size(40, 50),
      zIndex: 999
    })
    this.map.setZoomAndCenter(17, position)
  }

  showResults() {
    this.resultsDiv.style.display = 'block'
  }

  hideResults() {
    this.resultsDiv.style.display = 'none'
  }
}
