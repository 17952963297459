
import { wktToGeoJSON } from "@terraformer/wkt"
import { getDistrict } from "./service"

const GUAGNXI_ID = 156450000

export function districtToCode(province) {
  const districtMap = {
    '广西': GUAGNXI_ID
  }
  return districtMap[province]
}

/**
 * 行政区划搜索
 * @param { String } province 省名
 * @param { Function } callback 回调函数
 * @returns
 */
export async function districtSearch(province, callback) {
  const keyword = districtToCode(province)
  const res = await getDistrict({
    keyword,
    childLevel: 0,
    extensions: true
  })


  const districtList = res.data.data.district.map(item => {
    return {
      ...item,
      boundaries: wktToGeoJSON(item.boundary).coordinates
    }
  })
  callback(res.status, {
    districtList
  })
  return res
}
