import { placeSearch as placeSearchApi } from './service'

export class PlaceSearch {
  constructor(opt = {}) {
    this.city = opt.city
    this.pageSize = opt.pageSize || 20
    this.pageIndex = opt.pageIndex || 1
  }


  async search(keyWord, callback) {
    const res = await placeSearchApi({
      keyWord,
      mapBound: '-180,-90,180,90',
      level: '18',
      queryType: '7', // 地名搜索
      start: (this.pageIndex - 1) * this.pageSize,
      count: this.pageSize,
      show: '2'
    })

    const adapter = (placeSearchData) => {
      const { pois = [], status, count, ...rest } = placeSearchData
      const toAMapPOI = (data) => {
        return {
          name: data.name,
          phone: data.tele,
          address: data.address,
          pcode: data.provinceCode,
          citycode: data.cityCode,
          adcode: data.countyCode,
          pname: data.province,
          cityname: data.city,
          adname: data.county,
          poiType: data.poiType,
          eaddress: data.eaddress,
          ename: data.county,
          id: data.hotPointID,
          location: data.lonlat.split(',').map(Number)
        }
      }
      return {
        ...rest,
        info: status.infocode === 1000 ? 'complete' : 'error',
        poiList: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          count: Number(count),
          pois: pois.map(item => (toAMapPOI(item)))
        }
      }
    }
    const result = adapter(res.data)
    this.pageIndex++
    callback && callback(result.info, result) // 模拟数据结构
    return result
  }

  setCity(city) {
    this.city = city
  }

  searchNearBy(keyword, center, radius, callback) {
    throw new Error('not implemented')
  }

  searchInBounds(keyword, bounds, callback) {
    throw new Error('not implemented')
  }
}
