<template>
  <div class="task" v-loading="isLoading || allRequsetLoading">
    <!-- <el-button @click="clearMap">click</el-button> -->
    <!-- 设备相关信息弹出面板 -->
    <div class="historyTerminaldialog">
      <el-dialog
        class="chat"
        ref="deviceDataRef"
        v-dialogDragCustom
        :modal="false"
        :append-to-body="false"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :lock-scroll="false"
        :visible.sync="historyTerminaldialogVisible"
        :title="terminalDialogTitle"
        v-if="historyTerminaldialogVisible"
      >
        <span slot="title" class="dialog-header">
          <span v-if="terminal.dialogType === 'drone'">
            <span class="dia_title" v-show="monitorType === 'list'"
              >任务信息</span
            >
            <span class="dia_title" v-show="monitorType === 'point'"
              >设备信息</span
            >
            <span v-if="terminal.dialogType === 'drone'">
              <span
                class="dia_name"
                v-if="terminal.droneInfo.brand_name !== '彩虹'"
              >
                {{ brand_name }}
              </span>
              <span class="dia_name" v-else> 彩虹无人机 </span>
              <span
                class="trajectoryBar"
                @click="lookParams"
                v-show="
                  terminal.planeType === 'feima' && monitorType === 'list'
                "
              >
                <img
                  src="@/assets/img/homeDialog/moreInformation.png"
                  style="cursor: pointer"
                />
              </span>
            </span>
          </span>
          <span v-if="terminal.dialogType === 'terminal'" class="dia_title">
            {{ terminalName | filterAppName }}
            <div
              class="trajectoryBar"
              v-show="terminal.droneInfo && terminal.droneInfo.mission_id"
            >
              <span class="trajectory">移动轨迹</span>
              <el-switch
                class="switchTurn"
                @change="showThisTrack"
                v-model="isShowThisTrack"
              ></el-switch>
            </div>
          </span>
          <div v-else-if="terminal.dialogType === 'params'">
            <span
              class="dia_name"
              style="cursor: pointer"
              @click="returnDronne"
            >
              返回
            </span>
          </div>
        </span>
        <dtuPop
          ref="dtupop"
          :terminalData="terminal"
          :trackButtonValue="trackButtonValue"
          :monitorType="monitorType"
          :nowShowLayer="nowShowLayer"
          @setLocationInMap="setLocationInMap"
        ></dtuPop>
      </el-dialog>
    </div>
    <div id="map1" class="map"></div>
  </div>
</template>
<script>
import coordtransform from "@/utils/transform.js";
import transform from "@/utils/transform.js";
import { mapState, mapMutations } from "vuex";
import SwitchMap from "@/utils/switchMap.js";
import DrwaMapMarker from "@/components/backEndHome/drawMapMarker.js";
import RequestData from "@/components/backEndHome/requestDevicesData.js";
import { iconData } from "@/components/backEndHome/cors.js";
import { districtSearch } from "@/utils/mapUtils";


const turf = require("@turf/turf");

export default {
  components: {},
  props: {
    requestDataManager: {
      type: Object
    },
    onlineLayerData: {
      type: Object
    },
    deviceLayerData: {
      type: Object
    },
    allDeviceLayerData: {
      type: Object
    },
    netWorkIcon: {
      type: Object
    },
    allRequsetLoading: {
      //数据处理完后的加载状态
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      map: "", //地球实例

      mapIndex: 1, //选择不同地图
      tiandituLayer: {}, //天地图
      tianditutext: {}, //天地图文字
      googleLayer: {}, //谷歌影像地图
      tiandituYinxiangLayer: {}, //天地图影像
      switchMapManage: "",
      runEnv: window.runEnv,
      // requestDataManager: "",
      historySortiesListData: {},
      historyRainboxMassLayer: "",
      DrwaMapMarkerManage: "",
      terminal: {}, //弹窗信息
      trackButtonValue: false, //轨迹按钮状态
      isShowThisTrack: false, //是否显示轨迹
      historyTerminaldialogVisible: false, //历史终端弹框
      terminalDialogTitle: "设备信息",
      monitorType: "list",
      historySortPage: 1,
      historySortTotalPage: 0, //总的页数
      historySortieLayer: {
        彩虹无人机: { 广西: [], 其他: {} },
        测绘装备: [],
        终端设备: []
      },
      allDeviceLayer: {
        彩虹无人机: { 广西: [], 其他: {} },
        测绘装备: [],
        终端设备: []
      },
      allDeviceLayerTest: [],
      nowShowLayer: "online",
      onlineLayer: {}, //在线架次图层
      gxGcjPolygon: [],
      gxWgsPolygon: [],
      gxPolygon: [],
      onlineTerminal: [],
      isFitView: true, //自适应
      isRealTime: true,
      historySortiesIcon: [
        "/static/img/feimaToDetail/centerHome/historySortitsIcon/rainbow.png",
        "/static/img/feimaToDetail/centerHome/historySortitsIcon/otherDrone.png",
        "/static/img/feimaToDetail/centerHome/historySortitsIcon/mapping.png",
        "/static/img/feimaToDetail/centerHome/historySortitsIcon/terminal.png"
      ],
      allDeviceIcon: [
        "/static/img/feimaToDetail/centerHome/equipmentIcon/rianbow.png",
        "/static/img/feimaToDetail/centerHome/equipmentIcon/otherDorne.png",
        "/static/img/feimaToDetail/centerHome/equipmentIcon/mapping.png",
        "/static/img/feimaToDetail/centerHome/equipmentIcon/terminal.png"
      ],
      allDeviceData: [], //全部设备数据
      selectDatePage: 1, //选择时间页码
      isLoading: false,
      coordinate: "gcj02", //采用什么坐标系
      allDeviceIndex: -1, //所有的设备三个位置
      brand_name: "其他无人机",
      terminalName: "林业巡护", //设备信息title名称
      terminalTrack: {}, //终端轨迹集合
      isShowAllTrack: false, //是否打开显示全部终端轨迹集合
      allPolyline: [], //地图上所有线集合，用于纠偏
      allPolylinePath: {}, //所有线的地址集合，key为设备序列号
      onlineTrackTimer: {}, //轨迹定时器
      onlineHideData: {}, //在线被隐藏的数据
      clusterMaxZoom: 17, //聚合最大缩放层级
      corsData: iconData,
      corsCoverData: {},
      corsCheck: true
    };
  },

  computed: {
    ...mapState(["monitorPlaneData", "token"])
  },
  mounted() {
    this.init();
    window.getDroneInfo = this.getDroneInfo;
    let that = this;
    this.$EventBus.$on("showOrHideMarker", data => {
      // 判断要关闭或者异常的是架次还是设备
      if (data.type) {
        //架次
        if (data.isOnline) {
          //在线的
          if (data.sonLayer) {
            this.onlineHideData[data.sonLayer] = data.show ? "show" : "hide";
            if (this.onlineHideData[data.parent]) {
              this.onlineHideData[data.parent] = "showPart";
            }
          } else {
            this.onlineHideData[data.parent] = !data.show;
          }
          this.isRealTime = true;
          if (data.parent.indexOf("彩虹") === -1) {
            this.showOrHideOnline(data, "onlineLayer"); //除了彩虹的
          } else {
            if (data.sonLayer) {
              if (data.show) {
                this.filtrateOnlineRainbow(
                  data,
                  "showOnlineMarker",
                  "onlineLayer"
                );
              } else {
                this.filtrateOnlineRainbow(
                  data,
                  "hideOnlineMarker",
                  "onlineLayer"
                );
              }
            } else {
              if (data.show) {
                this.showOnlineMarker([
                  this.onlineLayer[0]["彩虹无人机"]["广西"]
                ]);
                for (let key in this.onlineLayer[0]["彩虹无人机"]["其他"]) {
                  this.showOnlineMarker([
                    this.onlineLayer[0]["彩虹无人机"]["其他"][key]
                  ]);
                }
              } else {
                this.hideOnlineMarker([
                  this.onlineLayer[0]["彩虹无人机"]["广西"]
                ]);
                for (let key in this.onlineLayer[0]["彩虹无人机"]["其他"]) {
                  this.hideOnlineMarker([
                    this.onlineLayer[0]["彩虹无人机"]["其他"][key]
                  ]);
                }
              }
            }
          }
        } else {
          this.isRealTime = false;
          this.showOrHideMassLayer("historySortieLayer", data);
        }
      } else {
        this.isRealTime = false;
        this.showOrHideMassLayer("allDeviceLayer", data);
      }
    });
    this.$EventBus.$on("switchShowOtherIcon", data => {
      if (this.nowShowLayer !== data.name || data.index !== -1) {
        this.onlineHideData = {};
        this.terminalTrack = {};
        this.onlineTerminal = [];
        this.switchShowOtherIcon(data);
      }
    });
    this.$EventBus.$on("selectDateShowHistoryData", data => {
      this.selectDateShowHistoryData(data);
    });
    this.$EventBus.$on("showCorsMarker", data => {
      this.corsCheck = data;
      if (this.corsCheck === false && this.corsCoverData != null) {
        this.deleteCorsMarkers();
      } else {
        this.initCorsMarker();
      }
    });
    /**
     * 显示所有的轨迹，20210729，只显示在线的
     */
    this.$EventBus.$on("showAllTrack", data => {
      this.showAllTrack(data);
    });
    /**
     * 打开关闭聚合
     */
    this.$EventBus.$on("showMaxZoom", data => {
      this.clusterMaxZoom = 17;
      if (!data) {
        this.clusterMaxZoom = 1;
      }
      this.corsCoverData.setMaxZoom(this.clusterMaxZoom);
      for (let key in this.onlineLayer[0]) {
        if (key !== "终端设备" && key !== "彩虹无人机") {
          this.onlineLayer[1][key].setMaxZoom(this.clusterMaxZoom);
        } else {
          for (let key2 in this.onlineLayer[0][key]) {
            try {
              let map = this.onlineLayer[0][key][key2].getMap();
              if (map)
                this.onlineLayer[0][key][key2].setMaxZoom(this.clusterMaxZoom);
            } catch {}
          }
        }
      }
    });
  },
  filters: {
    filterAppName(val) {
      if (val === '飞享终端') return '林业巡护';
      return val;
    },
  },
  methods: {
    ...mapMutations(["setMonitorPlaneData"]),
    clearMap() {
      // this.map.clearMap()
    },

    selectDateShowHistoryData(data) {
      this.requestDataManager
        .getDateSortiesList(data, this.selectDatePage)
        .then(res => {
          this.historySortiesListData["gcj02"][data.category] =
            res["gcj02"][data.category];
          this.historySortiesListData["wgs84"][data.category] =
            res["wgs84"][data.category];
          this.isLoading = true;
          let totalPage = Math.ceil(res.totalPage / res.per_page);
          if (this.selectDatePage < totalPage) {
            this.selectDatePage++;
            data.page = this.selectDatePage;
            this.selectDateShowHistoryData(data);
          } else {
            this.isLoading = false;
            this.selectDatePage = 1;
            this.initDrawDataMarker(
              this.historySortiesListData[this.coordinate],
              "historySortieLayer",
              this.historySortiesIcon,
              true,
              data.category
            );
          }
        });
    },
    showAllTrack(data) {
      this.isShowAllTrack = data;
      if (data) {
        this.getDeviceTrack();
      } else {
        for (let key in this.terminalTrack) {
          this.terminalTrack[key].hide();
        }
      }
    },
    /**
     *
     */
    getDeviceTrack() {
      this.$apis.getDeviceTrack({ type: "online" }, this.token).then(res => {
        for (let key in res.data.data) {
          if (!this.terminalTrack[key]) {
            let track = [];
            track = this.getTarckInData(
              res.data.data[key].data,
              res.data.data[key].brand_name
            );
            this.terminalTrack[key] = new AMap.Polyline({
              strokeColor: "#FB4439", //线颜色
              strokeWeight: 5, //线宽
              strokeStyle: "solid", //线样式
              path: track,
              zIndex: 112,
              extData: key
            });
            this.map.add(this.terminalTrack[key]);
          } else {
            this.terminalTrack[key].show();
          }
        }
      });
    },
    getTarckInData(data, brand_name) {
      let track = [];
      if (brand_name === "林业巡护") {
        if (this.coordinate === "wgs84") {
          track = data.map(point => {
            return coordtransform.gcj02towgs84(point.lon, point.lat);
          });
        } else {
          track = data.map(point => {
            return [point.lon, point.lat];
          });
        }
      } else {
        if (this.coordinate === "wgs84") {
          track = data.map(point => {
            return [point.lon, point.lat];
          });
        } else {
          track = data.map(point => {
            return coordtransform.wgs84togcj02(point.lon, point.lat);
          });
        }
      }
      return track;
    },
    // 筛选复选框时对海量点图层进行显隐显示
    showOrHideMassLayer(name, data) {
      if (data.show) {
        if (data.sonLayer) {
          if (data.sonLayer.indexOf("广西") === -1) {
            //不为广西

            if (data.parent !== "彩虹无人机") {
              try {
                this[name][data.parent][data.sonLayer].show();
              } catch {}
            } else {
              if (data.sonLayer === "其他") {
                for (let key in this[name][data.parent][data.sonLayer]) {
                  this[name][data.parent][data.sonLayer][key].show();
                }
              } else {
                this[name][data.parent]["其他"][data.sonLayer].show();
              }
            }
          } else {
            try {
              this[name]["彩虹无人机"]["广西"]["广西"].show();
            } catch {}
          }
        } else {
          if (data.parent.indexOf("彩虹") === -1) {
            for (let key in this[name][data.parent]) {
              try {
                this[name][data.parent][key].show();
              } catch {}
            }
          } else {
            this[name]["彩虹无人机"]["广西"]["广西"] &&
              this[name]["彩虹无人机"]["广西"]["广西"].show();
            for (let key in this[name]["彩虹无人机"]["其他"]) {
              try {
                this[name]["彩虹无人机"]["其他"][key].show();
              } catch {}
            }
          }
        }
      } else {
        if (data.sonLayer) {
          if (data.sonLayer.indexOf("广西") === -1) {
            //不为广西
            if (data.parent !== "彩虹无人机") {
              try {
                this[name][data.parent][data.sonLayer].hide();
              } catch {}
            } else {
              if (data.sonLayer === "其他") {
                for (let key in this[name][data.parent][data.sonLayer]) {
                  this[name][data.parent][data.sonLayer][key].hide();
                }
              } else {
                this[name][data.parent]["其他"][data.sonLayer].hide();
              }
            }
          } else {
            try {
              this[name]["彩虹无人机"]["广西"]["广西"].hide();
            } catch {}
          }
        } else {
          if (data.parent.indexOf("彩虹") === -1) {
            for (let key in this[name][data.parent]) {
              try {
                this[name][data.parent][key].hide();
              } catch {}
            }
          } else {
            this[name]["彩虹无人机"]["广西"]["广西"] &&
              this[name]["彩虹无人机"]["广西"]["广西"].hide();
            for (let key in this[name]["彩虹无人机"]["其他"]) {
              try {
                this[name]["彩虹无人机"]["其他"][key].hide();
              } catch {}
            }
          }
        }
      }
    },
    init() {
      setTimeout(() => {
        this.initMap();
      }, 300);
      // this.drawDeviceMarker();
    },

    initMap() {
      let map = new AMap.Map("map1", {
        resizeEnable: true,
        mapStyle: "amap://styles/dark",
        zoom: 4
      });
      this.map = map;
      this.switchMapManage = new SwitchMap(this.map);
      this.DrwaMapMarkerManage = new DrwaMapMarker(this.map, this.netWorkIcon);
      this.getSortiesListData();
      this.setBorder();
      this.initCorsMarker();
    },
    drawDeviceMarker(data) {
      this.allDeviceData = data;
    },
    // 获取列表数据
    getSortiesListData() {
      this.requestDataManager
        .getSortiesList({}, this.historySortPage)
        .then(res => {
          this.historySortiesListData = res;
          this.historySortTotalPage = res.totalPage;
          let totalPage = Math.ceil(this.historySortTotalPage / 2000);
          if (this.historySortPage < totalPage) {
            this.historySortPage++;
            this.getSortiesListData();
          } else {
            //   this.initDrawMarker(
            //   res[this.coordinate],
            //   "historySortieLayer",
            //   this.historySortiesIcon
            // );
          }
        });
    },
    // 显示在线点
    showOrHideOnline(data, name) {
      let that = this;
      if (data.show) {
        //要显示
        if (data.sonLayer) {
          if (that[name][0][data.parent][data.sonLayer]) {
            if (data.parent !== "终端设备") {
              // that[name][data.parent][data.sonLayer].forEach((item) => {
              that[name][1][data.parent] &&
                that[name][1][data.parent].addMarkers(
                  that[name][0][data.parent][data.sonLayer]
                );
              that[name][1][data.parent] &&
                that[name][1][data.parent].setMaxZoom(this.clusterMaxZoom);
              // });
            } else {
              that[name][0][data.parent][data.sonLayer].setMap(this.map);
              that[name][0][data.parent][data.sonLayer].setMaxZoom(
                this.clusterMaxZoom
              );
            }
          }
        } else {
          if (data.parent !== "终端设备") {
            let path = [];
            for (let key in that[name][0][data.parent]) {
              path = path.concat(that[name][0][data.parent][key]);
            }
            that[name][1][data.parent] &&
              that[name][1][data.parent].setMarkers(path);
          } else {
            for (let key in that[name][0][data.parent]) {
              that[name][0][data.parent][key].setMap(this.map);
            }
          }
        }
      } else {
        //隐藏的
        if (data.sonLayer) {
          if (that[name][0][data.parent][data.sonLayer])
            if (data.parent !== "终端设备")
              that[name][1][data.parent] &&
                that[name][1][data.parent].removeMarkers(
                  that[name][0][data.parent][data.sonLayer]
                );
            else that.map.remove(that[name][0][data.parent][data.sonLayer]);
          // that[name][0][data.parent][data.sonLayer].forEach(item=>{
          //   console.log(item,that[name][1]);
          //   that[name][1].removeMarker(item)
          // })
          // that.map.remove(that[name][data.parent][data.sonLayer]);
          // that.map.remove(that[name][data.parent][data.sonLayer]);
        } else {
          if (data.parent !== "终端设备") {
            that[name][1][data.parent] &&
              that[name][1][data.parent].setMarkers([]);
          } else {
            that.hideOnlineMarker(that[name][0][data.parent]);
          }
        }
      }
    },
    // 隐藏在线点
    hideOnlineMarker(data) {
      for (let key in data) {
        this.map.remove(data[key]);
      }
    },
    // 筛选在线彩虹点
    filtrateOnlineRainbow(data, name, layerName) {
      switch (data.sonLayer) {
        case "广西":
          this[name]([this[layerName][0]["彩虹无人机"]["广西"]]);
          break;
        case "其他":
          for (let key in this[layerName]["彩虹无人机"]["其他"]) {
            this[name]([this[layerName][0]["彩虹无人机"]["其他"][key]]);
          }
          break;
        default:
          this[name]([this[layerName][0]["彩虹无人机"]["其他"][data.sonLayer]]);
          break;
      }
    },
    showOnlineMarker(data) {
      data.forEach(item => {
        try {
          item.setMap(this.map);
        } catch {}
      });
    },
    drawOnlineDevice() {
      if (this.DrwaMapMarkerManage === "") {
        this.DrwaMapMarkerManage = new DrwaMapMarker(this.map);
      }
      // setTimeout(()=>{
      this.onlineLayer = this.DrwaMapMarkerManage.setOnlineMakers(
        this.onlineLayerData,
        // this.historySortiesListData.wgs84,
        this.isRealTime,
        this.coordinate,
        this.onlineHideData,
        this.clusterMaxZoom
      );
      // },3000)
      // this.historySortiesListData.wgs84["终端设备"]["乡村绘"] = this.historySortiesListData.wgs84["终端设备"]["乡村绘"].slice(1,10)
    },
    hideOldOnlineMarker(data) {
      for (let key in data) {
        this.map.remove(data[key]);
      }
    },
    setNewItem(item, type) {
      let category_name = item["_source"].category_name;
      let brand_name = item["_source"].brand_name;
      switch (type) {
        case "change":
          let changeOnlineLayerData = [];
          if (item._source.category_name === "终端设备") {
            changeOnlineLayerData = this.onlineLayer[0][
              item._source.category_name
            ][item._source.brand_name].getMarkers();
          } else {
            console.log("位置移动");
            changeOnlineLayerData = this.onlineLayer[1][
              item._source.category_name
            ].getMarkers();
          }
          changeOnlineLayerData.forEach((data, index) => {
            let id = data.getExtData();
            if (id === item._source.mission_id) {
              if (this.coordinate === "gcj02") {
                changeOnlineLayerData[index].setPosition(item.gcj02);
              } else {
                changeOnlineLayerData[index].setPosition(item.wgs84);
              }
            }
          });
          break;
        /**以下暂时未用到 */
        case "add":
          let data = {};
          let val = {};
          val[brand_name] = [item];
          data[category_name] = val;
          // let data = {item["_source"].category_name:{item._source.brand_name:item}}
          let layerData = this.DrwaMapMarkerManage.setOnlineMakers(
            data,
            this.isRealTime,
            this.coordinate,
            this.onlineHideData,
            this.clusterMaxZoom
          );
          this.onlineLayer[category_name][brand_name].push(
            layerData[category_name][brand_name]
          );
          break;
        case "remove":
          this.map.remove(this.onlineLayerData[category_name][brand_name]);
          break;
      }
    },
    mapUpdateOnlineDevice(category_name, brand_name, item) {
      let data = {};
      let val = {};
      val[brand_name] = item;
      let initCluster = false;
      if (category_name !== "彩虹无人机") {
        if (category_name !== "终端设备") {
          this.onlineLayer[0][category_name][brand_name] &&
            this.onlineLayer[1][category_name] &&
            this.onlineLayer[1][category_name].removeMarkers(
              this.onlineLayer[0][category_name][brand_name]
            );
        } else {
          initCluster = true;
          this.onlineLayer[0][category_name][brand_name] &&
            this.map.remove(this.onlineLayer[0][category_name][brand_name]);
        }
      } else {
        if (brand_name === "广西") {
          this.onlineLayer[0]["彩虹无人机"]["广西"] &&
            this.map.remove(this.onlineLayer[0]["彩虹无人机"]["广西"]);
        } else {
          this.onlineLayer[0][category_name]["其他"][brand_name] &&
            this.map.remove(
              this.onlineLayer[0][category_name]["其他"][brand_name]
            );
          val = { 其他: val };
        }
      }

      data[category_name] = val;
      // let data = {item["_source"].category_name:{item._source.brand_name:item}}
      let layerData = this.DrwaMapMarkerManage.setOnlineMakers(
        data,
        this.isRealTime,
        this.coordinate,
        this.onlineHideData,
        this.clusterMaxZoom,
        initCluster
      );
      if (category_name === "彩虹无人机" && brand_name !== "广西") {
        this.onlineLayer[0][category_name]["其他"][brand_name] =
          layerData[0][category_name]["其他"][brand_name];
      } else {
        this.onlineLayer[0][category_name][brand_name] =
          layerData[0][category_name][brand_name];
        if (
          category_name !== "终端设备" &&
          category_name !== "彩虹无人机" &&
          this.onlineLayer[0][category_name][brand_name][0] &&
          this.onlineLayer[0][category_name][brand_name][0].getMap()
        )
          this.onlineLayer[1][category_name].addMarkers(
            this.onlineLayer[0][category_name][brand_name]
          );
      }
    },
    initDrawMarker(res, dataName, icons, show = false) {
      let RainbowGxData = {};
      RainbowGxData["广西"] = res["彩虹无人机"]["广西"];
      let isPoint = dataName === "allDeviceLayer" ? true : false;
      this[dataName]["彩虹无人机"]["广西"] =
        this.DrwaMapMarkerManage &&
        this.DrwaMapMarkerManage.drawMassivePoint(
          RainbowGxData,
          icons[0],
          7,
          112,
          show,
          isPoint
        );
      for (let key in res["彩虹无人机"]["其他"]) {
        this.$set(this[dataName]["彩虹无人机"]["其他"], key, []);
        this[dataName]["彩虹无人机"][
          "其他"
        ] = this.DrwaMapMarkerManage.drawMassivePoint(
          res["彩虹无人机"]["其他"],
          icons[0],
          7,
          112,
          show,
          isPoint
        );
      }
      this[dataName]["无人机"] =
        this.DrwaMapMarkerManage &&
        this.DrwaMapMarkerManage.drawMassivePoint(
          res["无人机"],
          icons[1],
          7,
          111,
          show,
          isPoint
        );
      this[dataName]["测绘装备"] =
        this.DrwaMapMarkerManage &&
        this.DrwaMapMarkerManage.drawMassivePoint(
          res["测绘装备"],
          icons[2],
          7,
          111,
          show,
          isPoint
        );
      // 终端类别海量点，如果需要替换图标，统一替换直接替换icon[3],判断替换则进入drawMassivePoint函数进行判断替换
      this[dataName]["终端设备"] =
        this.DrwaMapMarkerManage &&
        this.DrwaMapMarkerManage.drawMassivePoint(
          res["终端设备"],
          icons[3],
          7,
          111,
          show,
          isPoint
        );
    },
    initDrawDataMarker(res, dataName, icons, show = false, category) {
      if (category === "彩虹无人机") {
        try {
          this.historySortieLayer["彩虹无人机"]["广西"]["广西"].clear();
          this.map.remove(
            this.historySortieLayer["彩虹无人机"]["广西"]["广西"]
          );
        } catch {}

        for (let key in this.historySortieLayer["彩虹无人机"]["其他"]) {
          try {
            this.historySortieLayer["彩虹无人机"]["其他"][key].clear();
            this.map.remove(this.historySortieLayer["彩虹无人机"]["其他"][key]);
          } catch {}
        }
      } else {
        for (let key in this.historySortieLayer[category]) {
          try {
            this.historySortieLayer[category][key].clear();
            this.map.remove(this.historySortieLayer[category][key]);
          } catch {
            console.log(key, "清除失败");
          }
        }
      }
      switch (category) {
        case "彩虹无人机":
          let RainbowGxData = {};
          RainbowGxData["广西"] = res["彩虹无人机"]["广西"];

          this[dataName]["彩虹无人机"]["广西"] =
            this.DrwaMapMarkerManage &&
            this.DrwaMapMarkerManage.drawMassivePoint(
              RainbowGxData,
              icons[0],
              7,
              112,
              show
            );
          for (let key in res["彩虹无人机"]["其他"]) {
            this.$set(this[dataName]["彩虹无人机"]["其他"], key, []);
            this[dataName]["彩虹无人机"][
              "其他"
            ] = this.DrwaMapMarkerManage.drawMassivePoint(
              res["彩虹无人机"]["其他"],
              icons[0],
              7,
              112,
              show
            );
          }
          break;
        case "无人机":
          this[dataName]["无人机"] =
            this.DrwaMapMarkerManage &&
            this.DrwaMapMarkerManage.drawMassivePoint(
              res["无人机"],
              icons[1],
              7,
              111,
              show
            );
          break;
        case "测绘装备":
          this[dataName]["测绘装备"] =
            this.DrwaMapMarkerManage &&
            this.DrwaMapMarkerManage.drawMassivePoint(
              res["测绘装备"],
              icons[2],
              7,
              111,
              show
            );
          break;
        default:
          // 终端类别海量点，如果需要替换图标，统一替换直接替换icon[3],判断替换则进入drawMassivePoint函数进行判断替换
          this[dataName]["终端设备"] =
            this.DrwaMapMarkerManage &&
            this.DrwaMapMarkerManage.drawMassivePoint(
              res["终端设备"],
              icons[3],
              7,
              111,
              show
            );
          break;
      }
    },
    // cors站海量点
    initCorsMarker(clusterMaxZoom) {
      let _this = this;
      let corsMarkerData = [];
      let lnglat = [];
      const list = _this.corsData.map((item, index) => {
        const data = JSON.parse(JSON.stringify(item));
        if (_this.mapIndex === 2 || _this.mapIndex === 3) {
          _this.deleteCorsMarkers();
          const [lng, lat] = coordtransform.gcj02towgs84(
            item.longitude,
            item.latitude
          );
          data.longitude = lng;
          data.latitude = lat;
        }
        let marker = _this.DrwaMapMarkerManage.drawCorsMarker(data);
        corsMarkerData.push(marker);
      });
      _this.corsCoverData = _this.DrwaMapMarkerManage.drawCorsCluster(
        corsMarkerData,
        clusterMaxZoom
      );
      return _this.corsCoverData;
    },
    // 绘制cors站海量点
    deleteCorsMarkers() {
      this.corsCoverData.setMap(null);
    },
    // 切换时显示其他图标，海量点格式
    switchShowOtherIcon(data) {
      this.nowShowLayer = data.name;
      this.map.clearMap();
      this.massLayerClear(this.historySortieLayer);
      this.setBorder();
      if (data.name === "online") {
        this.allDeviceIndex = -1;
        this.isRealTime = true;
        this.monitorType = "list";
        this.drawOnlineDevice();
        this.massLayerClear(this.allDeviceLayer);
        this.$emit("setTimerRun", true);
        if (this.corsCheck) {
          this.deleteCorsMarkers();
          this.initCorsMarker();
        }
      } else {
        this.isRealTime = false;
        this.$emit("setTimerRun", false);
        for (let key in this.onlineLayer[0]) {
          try {
            this.onlineLayer[1][key].setMap(null);
          } catch {}
          for (let key2 in this.onlineLayer[0][key]) {
            try {
              this.onlineLayer[0][key][key2].setMap(null);
            } catch {}
          }
        }

        if (data.index === -1) {
          this.monitorType = "list";
          this.allDeviceIndex = -1;

          this.massLayerClear(this.allDeviceLayer);
          let timer = null;
          this.isLoading = true;
          if (this.historySortiesListData[this.coordinate]) {
            this.initDrawMarker(
              this.historySortiesListData[this.coordinate],
              "historySortieLayer",
              this.historySortiesIcon,
              true
            );
            this.isLoading = false;
          } else {
            timer = setInterval(() => {
              if (this.historySortiesListData[this.coordinate]) {
                this.initDrawMarker(
                  this.historySortiesListData[this.coordinate],
                  "historySortieLayer",
                  this.historySortiesIcon,
                  true
                );
                clearInterval(timer);
                this.isLoading = false;
              }
            }, 1500);
          }
        } else {
          this.monitorType = "point";
          if (this.allDeviceIndex !== data.index) {
            this.allDeviceIndex = data.index;
            this.massLayerClear(this.allDeviceLayer);
            this.initDrawMarker(
              this.allDeviceData[data.index + 4][this.coordinate],
              "allDeviceLayer",
              this.historySortiesIcon,
              true
            );
          }

          // this.allDeviceLayer = this.DrwaMapMarkerManage.setDeviceMakers(
          //   this.allDeviceData[data.index],
          //   true,
          //   data.index,
          //   this.coordinate
          // );
        }
        for (let key in this.onlineTrackTimer) {
          clearInterval(this.onlineTrackTimer[key]);
        }
        this.deleteCorsMarkers();
      }
    },
    // 选择地图
    switchMap(index) {
      console.log("index", index);
      if (index !== this.mapIndex) {
        this.mapIndex = index;
        this.allPolyline = this.map.getAllOverlays("polyline");
        this.allPolylinePath = {};
        this.switchMapManage.switchDifferentMap(index).then(data => {
          if (data) {
            this.coordinate = "wgs84";
            this.allPolyline.forEach(item => {
              let path = item.getPath();
              let wgsPath = path.map(point => {
                return coordtransform.gcj02towgs84(point.R, point.Q);
              });
              let extDate = item.getExtData();
              this.allPolylinePath[extDate] = wgsPath;
            });
          } else {
            if (this.corsCheck) {
              this.deleteCorsMarkers();
              this.initCorsMarker();
            }
            this.coordinate = "gcj02";
            this.allPolyline.forEach(item => {
              let path = item.getPath();
              let gcjPath = path.map(point => {
                return coordtransform.wgs84togcj02(point.R, point.Q);
              });
              let extDate = item.getExtData();
              this.allPolylinePath[extDate] = gcjPath;
            });
          }

          this.switchCoordinate();
          console.log("this.corsCoverData", this.corsCoverData);
        });
        // this.map.setCenter(this.trajectory[0]);
      }
    },
    // 切换地图
    switchCoordinate() {
      this.map.clearMap();
      this.massLayerClear(this.historySortieLayer);
      this.massLayerClear(this.allDeviceLayer);
      this.setBorder();
      if (this.nowShowLayer === "online") {
        this.drawOnlineDevice();
        for (let key in this.allPolylinePath) {
          this.terminalTrack[key].setPath(this.allPolylinePath[key]);
          this.terminalTrack[key].setMap(this.map);
        }
        if (this.corsCheck) {
          this.deleteCorsMarkers();
          this.initCorsMarker();
        }
      } else if (this.nowShowLayer === "historySortieLayer") {
        this.initDrawMarker(
          this.historySortiesListData[this.coordinate],
          "historySortieLayer",
          this.historySortiesIcon,
          true
        );
        for (let key in this.allPolylinePath) {
          this.terminalTrack[key].setPath(this.allPolylinePath[key]);
          this.terminalTrack[key].setMap(this.map);
        }
      } else {
        // this.allDeviceLayer = this.DrwaMapMarkerManage.setDeviceMakers(
        //   this.allDeviceData[this.allDeviceIndex],
        //   true,
        //   this.allDeviceIndex,
        //   this.coordinate
        // );
        this.initDrawMarker(
          this.allDeviceData[this.allDeviceIndex + 4][this.coordinate],
          "allDeviceLayer",
          this.historySortiesIcon,
          true
        );
      }
    },
    // 绘制历史架次图标
    drawHistroySortie() {},
    // 获取无人机信息
    getDroneInfo(drone, planeType = "feima", isHistory = false, deviceLayer) {
      const param = {
        ...drone,
        showEnterButton: true,
        software_code:
          drone.uas_serial_id || drone.uavNumber || drone.software_code,
        type: "drone",
        planeType: planeType,
        isHistory: isHistory,
        deviceLayer: deviceLayer
        // OrderID: drone.OrderID
      };
      if (this.nowShowLayer !== "online") {
        if (
          drone.brand_name === "林业巡护" ||
          drone.brand_name === "监控模块"
        ) {
          const hasLocalData = this.checkTerminalData(drone);
          if (hasLocalData) {
            this.trackButtonValue = this.terminal.track.visible;
            this.isShowThisTrack = this.terminal.track.visible;
            this.terminalName = this.terminal.device;
            this.historyTerminaldialogVisible = !this
              .historyTerminaldialogVisible;

            return;
          }
        }
      }
      this.getDeviceDetailInfo(param);
    },
    // 设备边界
    setBorder() {
      let e = this.map;
      let self = this;
      //高亮广西省边界
      // 搜索所有省/直辖市信息
      districtSearch("广西", (status, result) => {
        // 查询成功时，result即为对应的行政区信息
        self.gxGcjPolygon = result.districtList[0].boundaries.map(polygons=> polygons.map(path=>
          path.map(item=>
            coordtransform.wgs84togcj02(
              item[0],
              item[1]
            ))
          )
        );
        self.handlePolygon(self.gxGcjPolygon);

        if (self.gxWgsPolygon.length === 0) {
          self.gxGcjPolygon.forEach((items, index) => {
            self.gxWgsPolygon[index] = [];
            items.forEach((data, i) => {
              self.gxWgsPolygon[index].push(
                coordtransform.gcj02towgs84(data.lat, data.lng)
              );
            });
          });
        }
      });
    },
    handlePolygon(result) {
      let bounds = result;
      let self = this;
      self.gxPolygon = [];
      if (bounds.length) {
        for (let i = 0, l = bounds.length; i < l; i++) {
          //生成行政区划polygon
          self.gxPolygon.push(
            new AMap.Polygon({
              map: self.map, // 指定地图对象
              strokeWeight: 2, // 轮廓线宽度
              path: bounds[i], //轮廓线的节点坐标数组
              fillOpacity: 0.15, //透明度
              fillColor: "transparent", //填充颜色
              strokeColor: "#256edc" //线条颜色
            })
          );
        }
        // 地图自适应
        if (this.isFitView) {
          self.map.setFitView();
          this.isFitView = false;
        }
      }
    },
    // 获取设备详细信息
    getDeviceDetailInfo(param, open = true) {
      const uuid = param.uuid != null ? param.uuid : "";
      const software_code =
        param.software_code != null ? param.software_code : "";
      const droneInfo = param.type === "drone" && param;
      this.$apis
        .getDeviceDetailInfo(
          {
            uuid,
            software_code
          },
          this.token
        )
        .then(data => {
          if (
            JSON.stringify(data.data.data) === "[]" ||
            data.data.data == null
          ) {
            // 重置terminal, 避免报 undefined
            this.terminal = {
              device_data: {
                software_code: ""
              },
              user_data: {}
            };
          } else {
            this.terminal = { ...this.terminal, ...data.data.data };
          }
          this.terminal.track =
            param.track == null ? { visible: false } : param.track;
          if (this.isShowAllTrack && this.nowShowLayer === "online") {
            this.terminal.track.visible = true;
          }
          this.trackButtonValue = this.terminal.track.visible;
          this.isShowThisTrack = this.terminal.track.visible;

          this.terminal.droneInfo = droneInfo;
          this.terminal.isSurveyingmapping = false;
          this.$set(this.terminal, "missionId", param.mission_id!=="" ? param.mission_id : param.software_code );
          this.brand_name = "其他无人机";
          if (droneInfo.category_name === "无人机") {
            this.terminal.dialogType = "drone";
            this.terminal.device = "无人机";
          } else {
            this.terminal.terminal_track = null;
            if (param.mission_id) {
              this.getTerminalTrack({
                software_code: param.software_code,
                mission_id: param.mission_id
              }).then(track => {
                const hasLocalData = this.checkTerminalData(param);
                let index = -1;
                if (hasLocalData) {
                  index = this.onlineTerminal.findIndex(item => {
                    return (
                      item.droneInfo.software_code === param.software_code &&
                      item.droneInfo.mission_id == param.mission_id
                    );
                  });
                }
                if (track) {
                  this.terminal.terminal_track = track;
                }
                if (this.nowShowLayer === "online") {
                  this.terminal.draw = true;
                } else {
                  this.terminal.draw = false;
                }
                if (index === -1) {
                  this.onlineTerminal.push(this.terminal);
                } else {
                  this.terminal.track.visible = this.onlineTerminal[
                    index
                  ].track.visible;
                  this.isShowThisTrack = this.onlineTerminal[
                    index
                  ].track.visible;
                  this.onlineTerminal.splice(index, 1, this.terminal);
                }
              });
            } else {
              this.onlineTerminal.push(this.terminal);
            }
          }
          if (droneInfo.category_name === "终端设备") {
            this.terminalName = droneInfo.brand_name;
            this.brand_name = droneInfo.brand_name;
            this.terminal.device = droneInfo.brand_name;
            this.terminal.dialogType = "terminal";
            this.terminal.isSurveyingmapping = true;
          }
          if (droneInfo.category_name === "测绘装备") {
            this.terminal.dialogType = "terminal";
            this.terminal.device = "测绘装备";
            this.brand_name = "测绘装备";
            this.terminalName = "测绘装备";
            this.terminal.isSurveyingmapping = true;
          }
          if (param.planeType !== undefined) {
            this.terminal.planeType = param.planeType;
            this.terminal.isHistory = param.isHistory;
          }
          this.terminal.nowloaction =
            param.nowloaction === undefined ? [] : param.nowloaction;
          this.terminal.deviceLayer = param.deviceLayer;
          setTimeout(() => {
            if (open && data.data.data.device_data !== undefined) {
              this.historyTerminaldialogVisible = true;
            } else {
              this.$message(data.data.message);
            }
            // this.$refs.dtupop.open();
          }, 100);
          // }
        })
        .catch(err => {
          console.error(err);
        });
    },
    // 查看更多数据
    lookParams() {
      this.$message("功能暂未开放");
    },
    setLocationInMap(lnglat, deviceType = "drone", locationData) {
      this.historyTerminaldialogVisible = false;
      if (deviceType === "drone") {
        lnglat = coordtransform.wgs84togcj02(lnglat[0], lnglat[1]);
      }
      if (this.coordinate === "wgs84") {
        lnglat = coordtransform.gcj02towgs84(lnglat[0], lnglat[1]);
      }
      this.map.setZoomAndCenter(17, lnglat);
      let icon = new AMap.Icon({
        size: new AMap.Size(20, 30), // 图标尺寸
        image:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png", // Icon的图像
        // imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(20, 30) // 根据所设置的大小拉伸或压缩图片
      });
      this.deviceMarker = new AMap.Marker({
        icon: icon,
        map: this.map,
        position: lnglat,
        size: new AMap.Size(40, 50),
        zIndex: 999
      });
    },
    // 海量点图层清空
    massLayerClear(data) {
      try {
        data["彩虹无人机"]["广西"]["广西"].clear();
        this.map.remove(data["彩虹无人机"]["广西"]["广西"]);
      } catch {}

      for (let key in data["彩虹无人机"]["其他"]) {
        try {
          data["彩虹无人机"]["其他"][key].clear();
          this.map.remove(data["彩虹无人机"]["其他"][key]);
        } catch {}
      }
      for (let key in data) {
        if (key.indexOf("彩虹") === -1) {
          for (let key1 in data[key]) {
            data[key][key1].clear();
            this.map.remove(data[key][key1]);
          }
        }
      }
    },
    // 处理终端数据
    checkTerminalData(device) {
      const index = this.onlineTerminal.findIndex(item => {
        return (
          item.droneInfo.software_code === device.software_code &&
          item.droneInfo.mission_id == device.mission_id
        );
      });
      if (index === -1) {
        return false;
      } else {
        this.terminal = this.onlineTerminal[index];
        return true;
      }
    },
    // 设备获取接口封装
    getDeviceData(params) {
      return new Promise((resolve, reject) => {
        this.$apis
          .getDeviceDetailInfo(params, this.token)
          .then(res => {
            if (
              JSON.stringify(res.data.data) === "[]" ||
              res.data.data == null
            ) {
              // 重置terminal, 避免报 undefined
              const deviceData = {
                device_data: {
                  software_code: ""
                },
                user_data: {}
              };
              resolve(deviceData);
            } else {
              resolve(res.data.data);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getTerminalTrack(params) {
      return new Promise((resolve, reject) => {
        this.$apis.getDeviceMonitorData(params, this.token).then(res => {
          if (JSON.stringify(res.data.data) === "[]" || res.data.data == null) {
            reject();
          } else {
            resolve(res.data.data);
          }
        });
      });
    },
    // 显示轨迹
    showThisTrack() {
      this.terminal.track.visible = this.isShowThisTrack;
      let keyName = "";
      if (this.nowShowLayer === "online") {
        keyName = this.terminal.device_data.software_code;
      } else {
        keyName = this.terminal.missionId;
      }
      if (this.isShowThisTrack && this.terminal.terminal_track) {
        if (this.terminal.track && this.terminalTrack[keyName]) {
          // do nothing
          if (this.terminal.draw) {
            this.trackDrawPolyline(keyName);
          } else {
            this.terminalTrack[keyName].show();
          }
        } else {
          if (!this.terminalTrack[keyName]) {
            this.trackDrawPolyline(keyName);
          } else {
            let data = JSON.parse(JSON.stringify(this.terminal));
            this.agginGetTrack(keyName, data).then(res => {
              this.terminalTrack[keyName].show();
            });
          }
        }
        if (this.nowShowLayer === "online") {
          let data = JSON.parse(JSON.stringify(this.terminal));

          this.onlineTrackTimer[this.terminal.missionId] = setInterval(() => {
            this.agginGetTrack(keyName, data);
          }, 3000);
        }
      } else {
        this.terminalTrack[keyName] && this.terminalTrack[keyName].hide();
        clearInterval(this.onlineTrackTimer[this.terminal.missionId]);
      }
    },
    /**
     * 重新获取到设备的轨迹
     */
    agginGetTrack(keyName, data) {
      new Promise((resolve, reject) => {
        this.getTerminalTrack({
          software_code: data.device_data.software_code,
          mission_id: data.missionId
        }).then(track => {
          data.terminal_track = track;
          track = this.getTarckInData(data.terminal_track, data.device);

          this.terminalTrack[keyName].setPath(track);
          resolve(track);
        });
      });
    },
    trackDrawPolyline(keyName) {
      let track = [];
      track = this.getTarckInData(
        this.terminal.terminal_track,
        this.terminal.device
      );
      this.terminalTrack[keyName] = new AMap.Polyline({
        strokeColor: "#FB4439", //线颜色
        strokeWeight: 5, //线宽
        strokeStyle: "solid", //线样式
        path: track,
        zIndex: 112,
        extData: keyName
      });
      this.map.add(this.terminalTrack[keyName]);
    }
  },
  beforeDestroy() {
    //移除监听事件"showOrHideMarker"
    this.$EventBus.$off("showOrHideMarker");
    this.$EventBus.$off("switchShowOtherIcon");
    this.$EventBus.$off("showAllTrack");
    this.$EventBus.$off("showMaxZoom");
    this.$EventBus.$off("showCorsMarker");

    for (let key in this.onlineTrackTimer) {
      clearInterval(this.onlineTrackTimer[key]);
    }
  }
};
</script>
<style lang="scss" scoped>
.task {
  height: 100%;
  position: relative;
  overflow: hidden;
  /deep/.chat {
    width: 316px;
    height: 530px;
    left: calc(50% - 450px);
    top: calc(50% - 343px);
    > .el-dialog:first-child {
      width: 100%;
      margin-top: 0 !important;
      .el-dialog__header {
        // position: absolute;
        right: 0;
        z-index: 998;
        width: 100%;
        // height: 70px;
        .chat .el-dialog__headerbtn {
          top: 10px;
        }
      }
      .el-dialog__body {
        padding: 0;
      }
    }
  }
  /deep/.amap-logo {
    right: 0 !important;
    left: auto !important;
    display: none !important !important;
  }
  /deep/.el-loading-mask {
    opacity: 0.5;
  }
  .map {
    height: 100%;
  }
  .portal-panel {
    .online {
      z-index: 999;
      width: 112px;
      height: 105px;
      background: linear-gradient(
        134deg,
        rgba(0, 111, 255, 1) 0%,
        rgba(71, 65, 255, 1) 70%
      );
      border-radius: 14px;
      position: absolute;
      left: 41px;
      top: 98px;
      display: grid;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .history {
      z-index: 999;
      width: 112px;
      height: 105px;
      background: linear-gradient(
        132deg,
        rgba(2, 121, 197, 1) 0%,
        rgba(1, 68, 147, 1) 70%
      );
      border-radius: 14px;
      position: absolute;
      left: 41px;
      top: 226px;
      display: grid;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .flying {
      font-size: 30px;
      color: #ffffff;
      margin-left: 9px;
      margin-top: 10px;
    }
    .count {
      font-size: 20px;
      font-family: DINPro-Bold, DINPro;
      font-weight: bold;
      color: #fff;
      margin: 0;
      line-height: 26px;
      text-align: center;
    }

    .title {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: rgba(255, 255, 255, 1);
      line-height: 16px;
      margin: 0;
    }
    .bottBar {
      margin-bottom: 5px;
    }
    .setup {
      position: absolute;
      right: 42px;
      top: 82px;
      width: 40px;
      height: 40px;
      background-color: #014493;
      border-radius: 8px;
      z-index: 999;
      background: url("~@/assets/img/gatewayImg/首页-显隐.png") center no-repeat;
      cursor: pointer;
    }
    .historydeta {
      z-index: 998;
      position: absolute;
      top: 226px;
      left: 82px;
      width: 220px;
      height: 105px;
      border-radius: 14px;
      background-color: #000000;
      opacity: 0.5;
      display: grid;
      align-items: center;
      justify-content: end;
    }
    .onlinedeta {
      z-index: 998;
      position: absolute;
      top: 98px;
      left: 82px;
      width: 220px;
      height: 105px;
      border-radius: 14px;
      background-color: #000000;
      opacity: 0.5;
      display: grid;
      align-items: center;
      justify-content: end;
    }
    .line {
      color: #ffffff;
      z-index: 999;
      width: 150px;
    }
    .brand {
      color: #ffffff;
      font-size: 12px;
      float: left;
      margin-left: 15px;
    }
    .line:hover {
      height: 20px;
      cursor: pointer;
    }
    .feima {
      float: right;
      color: #32f793;
      font-size: 12px;
      margin-right: 10px;
      width: 25px;
    }
    .tongyong {
      float: right;
      color: #49b2ff;
      font-size: 12px;
      margin-right: 10px;
      width: 25px;
    }
    .yidong {
      float: right;
      color: #fe4230;
      font-size: 12px;
      margin-right: 10px;
      width: 25px;
    }
    .circelone {
      float: right;
      font-size: 12px;
      color: #32f793;
      margin-right: 10px;
    }
    .circeltwo {
      float: right;
      font-size: 12px;
      color: #49b2ff;
      margin-right: 10px;
    }
    .circelthree {
      float: right;
      font-size: 12px;
      color: #fe4230;
      margin-right: 10px;
    }
  }
  .historyTerminaldialog {
    .dialog-header {
      position: relative;
      width: 100%;
      display: inline-block;
      .dia_title {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin-right: 10px;
        // line-height: 21px;
      }
      .dia_name {
        font-size: 12px;
        color: #69789e;
      }

      .trajectoryBar {
        align-self: flex-end;
        bottom: -2px;
        position: absolute;
        right: 50px;
        // margin: 60px 20px 40px 0;
        .trajectory {
          margin-right: 14px;
          font-size: 12px;
          color: #69789e;
        }
        button {
          border-radius: 20px;
          background-color: #0052f7;
          border-color: #1890ff;
          color: #ffffff;
          opacity: 0.7;
        }
        button:hover {
          opacity: 1;
        }
      }
    }
    /deep/.el-dialog__title {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
    /deep/.el-divider--horizontal {
      margin: 0 !important;
    }
    /deep/.el-dialog {
      max-height: 480px !important;
      width: 316px !important;
      height: 56px;
      background: #001125;
      border-radius: 2px;
    }
    /deep/.el-dialog__header {
      height: 56px;
      background: #000b18 !important;
      padding: 18px 0 0 32px;
      border: 2px solid rgba(10, 110, 247, 0.4);
      border-bottom: 1px solid rgba(10, 110, 247, 0.4);
    }
    /deep/.el-dialog__body {
      padding: 0;
      // height: 90%;
      background: #001125 !important;
      border: 2px solid rgba(10, 110, 247, 0.4);
      border-top: none;
    }
  }
}
</style>
