import axios from 'axios'

/**
 *
 * @param {*} lnglat
 * @param {*} callback
 */
export function getAddress(lnglat) {
  return axios.get('http://api.tianditu.gov.cn/geocoder', {
    params: {
      postStr: JSON.stringify({
        lon: lnglat[0],
        lat: lnglat[1],
        ver: 1
      }),
      type: 'geocode',
      tk: process.env.VUE_APP_TIANDITU_KEY
    }
  })
}

/**
 *
 * @param {Object} options
 * @param {String} options.keyWord 关键字
 *
 * @returns
 */
export function getDistrict(options) {
  return axios.get(`https://api.tianditu.gov.cn/v2/administrative`, {
    params: {
      ...options,
      tk: process.env.VUE_APP_TIANDITU_KEY
    }
  })
}


/**
 * 1 行政区划区域搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字
 * @param {String} options.specify 指定行政区的国标码（行政区划编码表）严格按照行政区划编码表中的（名称，gb码）
 * @param {String} options.queryType 搜索类型。服务查询类型参数 12：行政区划区域搜索服务
 * @param {String} options.start 返回结果起始位（用于分页和缓存）默认0。0-300，表示返回结果的起始位置。
 * @param {String} options.count 返回的结果数量（用于分页和缓存）。 1-300，返回结果的条数。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
/**
 * 2 视野内搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字
 * @param {String} options.mapBound 地图视野范围(“minx,miny,maxx,maxy”)
 * @param {String} options.level 目前查询的级别
 * @param {String} options.queryType 搜索类型。服务查询类型参数 2：视野内搜索。
 * @param {String} options.start 返回结果起始位（用于分页和缓存）默认0。0-300，表示返回结果的起始位置。
 * @param {String} options.count 返回的结果数量（用于分页和缓存）。 1-300，返回结果的条数。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
/**
 * 3 周边搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字
 * @param {String} options.queryRadius 查询半径 单位:米（10公里内）
 * @param {String} options.pointLonlat 点坐标 中心点，经纬度坐标
 * @param {String} options.queryType 搜索类型。服务查询类型参数 3：周边搜索服务。
 * @param {String} options.start 返回结果起始位（用于分页和缓存）默认0。0-300，表示返回结果的起始位置。
 * @param {String} options.count 返回的结果数量（用于分页和缓存）。 1-300，返回结果的条数。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
/**
 * 4 多边形搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字
 * @param {String} options.polygon 多边形范围数据(经纬度坐标对)。经度和纬度用","分割，首尾坐标对需相同。实例(x1,y1,x2,y2,x3,y3,x4,y4,x5,y5,x1,y1)
 * @param {String} options.queryType 搜索类型。服务查询类型参数 10：多边形搜索服务。
 * @param {String} options.start  返回结果起始位（用于分页和缓存）默认0。0-300，表示返回结果的起始位置。
 * @param {String} options.count 返回的结果数量（用于分页和缓存）。 1-300，返回结果的条数。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
/**
 * 5 数据分类搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字
 * @param {String} options.mapBound 查询的地图范围(“minx,miny,maxx,maxy”)。-180,-90至180,90。
 * @param {String} options.queryType 搜索类型。服务查询类型参数 13:分类搜索服务。
 * @param {String} options.start 返回结果起始位（用于分页和缓存）默认0。0-300，表示返回结果的起始位置。
 * @param {String} options.count 返回的结果数量（用于分页和缓存）。 1-300，返回结果的条数。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
/**
 * 6 普通搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字
 * @param {String} options.mapBound 查询的地图范围(“minx,miny,maxx,maxy”)。-180,-90至180,90。
 * @param {String} options.level 目前查询的级别，1-18级
 * @param {String} [options.specify] 指定行政区的国标码（行政区划编码表）严格按照行政区划编码表中的（名称，gb码）。如指定的行政区划编码是省以上级别则返回是统计数据（不包括直辖市）
 * @param {String} options.queryType 搜索类型。服务查询类型参数 1:普通搜索（含地铁公交） 7：地名搜索
 * @param {String} options.start 返回结果起始位（用于分页和缓存）默认0。0-300，表示返回结果的起始位置。
 * @param {String} options.count 返回的结果数量（用于分页和缓存）。 1-300，返回结果的条数。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
/**
 * 7 统计搜索服务
 * @overload
 * @param {Object} options
 * @param {String} options.keyWord 搜索的关键字·
 * @param {String} options.specify 指定行政区的国标码（行政区划编码表）严格按照行政区划编码表中的（名称，gb码）。如指定的行政区划编码是省以上级别则返回是统计数据（不包括直辖市）
 * @param {String} options.queryType 搜索类型。服务查询类型参数 14：统计搜索服务。
 * @param {String} [options.dataTypes] 数据分类（分类编码表）
 * @param {String} [options.show] 返回poi结果信息类别。取值为1，则返回基本poi信息； 取值为2，则返回详细poi信息
 * @returns
 */
export function placeSearch(options) {
  const postStr = JSON.stringify({
    ...options
  })
  return axios.get(`https://api.tianditu.gov.cn/v2/search`, {
    params: {
      postStr,
      type: 'query',
      tk: process.env.VUE_APP_TIANDITU_KEY
    }
  })
}
