export class CustomEventTarget {
  constructor() {
    this.listeners = {} // Store event listeners
  }

  /**
   * Adds an event listener.
   * @param {string} eventName The name of the event.
   * @param {function} callback The callback function to execute when the event is dispatched.
   * @returns {void}
   */
  on(eventName, callback) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = []
    }
    this.listeners[eventName].push(callback)
  }

  /**
   * Removes an event listener.
   * @param {string} eventName The name of the event.
   * @param {function} callback The callback function to remove.
   * @returns {void}
   */
  off(eventName, callback) {
    if (this.listeners[eventName]) {
      this.listeners[eventName] = this.listeners[eventName].filter(
        listener => listener !== callback
      )
    }
  }

  /**
   * Dispatches an event.
   * @param {string} eventName The name of the event to dispatch.
   * @param {any} [data] Optional data to pass to the event listeners.
   * @returns {void}
   */
  dispatchEvent(eventName, data) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].forEach(listener => {
        listener(data) // Call each listener with the provided data
      })
    }
  }


  /**
   * Dispatches a custom event.  More aligned with the standard EventTarget.dispatchEvent.
   * @param {CustomEvent} event The custom event to dispatch.
   * @returns {boolean} Whether preventDefault was called on the event.
   */
  dispatchCustomEvent(event) {
    let defaultPrevented = false

    if (this.listeners[event.type]) {
      this.listeners[event.type].forEach(listener => {
         if (event.defaultPrevented) { // Check if preventDefault has already been called.
            defaultPrevented = true
            return // Stop executing listeners if preventDefault has been called
         }

        listener(event)
        if (event.defaultPrevented) { //  Re-check for preventDefault after listener execution.
            defaultPrevented = true
        }
      })
    }

    return defaultPrevented
  }
}
