<template>
  <div>
    <div :class="['weatherMap', runEnv == 'backend' ? 'backend' : 'portal']">
      <div
        id="map1"
        class="map"
        style="height: 100%"
        v-show="currentOption != 'annualStat'"
      ></div>

      <div
        id="3dMap"
        class="map-container"
        style="height: 100%"
        v-show="currentOption == 'annualStat'"
      ></div>

      <div
        class="annual-stat-temp-control"
        v-show="currentOption == 'annualStat'"
      >
        <div class="hint">数据从2021年6月1日起开始统计</div>
        <div class="block-list">
          <div class="block" style="background: #e31a1d"></div>
          <div class="block" style="background: #fc4e2b"></div>
          <div class="block" style="background: #feb24c"></div>
          <div class="block" style="background: #ffeda0"></div>
        </div>
        <div class="text-list">
          <div class="block">降雨</div>
          <div class="block">阴天</div>
          <div class="block">多云</div>
          <div class="block">晴天</div>
        </div>
      </div>

      <div v-show="currentOption == 'monthStat'">
        <div class="city-select-container">
          <div class="city-name">{{ currentCity }}</div>
          <div class="change-btn" @click="isSelectCityMonthStat = true">
            &nbsp;[切换城市]
          </div>
        </div>
        <div class="city-select-open-card" v-show="isSelectCityMonthStat">
          <div class="current-city">
            <div>
              当前城市:<span class="select-item">&nbsp;{{ currentCity }}</span>
            </div>
          </div>
          <div class="hint-line">城市选择</div>
          <div class="city-list">
            <div style="width: 100%">
              <span
                class="item"
                :class="{ 'select-item': currentCity == city.name }"
                v-for="city in cityData"
                :key="city.id"
                @click="getMonthlyStat(city.name)"
              >
                {{ city.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="city-month-stat-data">
          <div class="title">
            <span>月分布统计占比</span>
            <span class="city">{{ currentCity }}</span>
            <div class="weather-item">
              <div class="block" style="background: #ffc616"></div>
              晴天
            </div>
            <div class="weather-item">
              <div class="block" style="background: #1fd0be"></div>
              多云
            </div>
            <div class="weather-item">
              <div class="block" style="background: #1980ff"></div>
              阴天
            </div>
            <div class="weather-item">
              <div class="block" style="background: #5166b5"></div>
              降雨
            </div>
          </div>
          <div id="chart" class="chart-box"></div>
        </div>
      </div>

      <div v-show="currentOption == 'today'">
        <!-- 左下角天气显示框 -->
        <div
          class="weather-list-card"
          :style="{ width: weatherListCardIsOpen ? '1032px' : '398px' }"
        >
          <div class="province-title">广西14地级行政区天气预报</div>
          <div
            class="city-card-list"
            :class="{ 'city-card-list-open': weatherListCardIsOpen }"
          >
            <div
              class="city-card"
              v-for="city in cityData"
              :key="city.id"
              @click="jumpCityWeatherForecast(city.name)"
            >
              <div class="city-name">{{ city.name }}</div>
              <div class="city-weather">
                <img
                  :src="'static/img/weathercn/color-64/' + city.icon + '.png'"
                />
                {{ city.text }}
              </div>
            </div>
          </div>
          <div
            class="open-btn"
            @click="weatherListCardIsOpen = !weatherListCardIsOpen"
          >
            <i v-if="weatherListCardIsOpen" class="el-icon-d-arrow-left"></i>
            <i v-else class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <!-- 右下角降水颜色表示 -->
        <div class="rain-color-card">
          <div class="item">
            <span class="color-block" style="background: #b6ecc3"></span>
            <span class="colorname">小雨</span>
          </div>
          <div class="item">
            <span class="color-block" style="background: #56bb81"></span>
            <span class="colorname">中雨</span>
          </div>
          <div class="item">
            <span class="color-block" style="background: #40a1a6"></span>
            <span class="colorname">大雨</span>
          </div>
          <div class="item">
            <span class="color-block" style="background: #fcf9f2"></span>
            <span class="colorname">晴天</span>
          </div>
        </div>
      </div>

      <!-- 右上角温度、湿度选项栏 -->
      <div
        class="map-option"
        v-show="
          currentOption == 'today' ||
          currentOption == 'temp' ||
          currentOption == 'humidity'
        "
      >
        <div
          class="imgBar"
          style="width: 98px"
          @click="setWeatherOption('temp')"
        >
          <span class="signWord">温度</span>
          <img
            v-if="currentOption == 'temp'"
            src="static/img/weathercn/temp_select.png"
          />
          <img v-else src="static/img/weathercn/temp_no_select.png" />
        </div>
        <div
          class="imgBar"
          style="width: 126px"
          @click="setWeatherOption('humidity')"
        >
          <span class="signWord">相对湿度</span>
          <img
            v-if="currentOption == 'humidity'"
            src="static/img/weathercn/humidity_select.png"
          />
          <img v-else src="static/img/weathercn/humidity_no_select.png" />
        </div>
      </div>

      <!-- 右上角顶部选项栏 -->
      <div class="stat-option">
        <div
          class="bar"
          :class="{
            'bar-select':
              currentOption == 'today' ||
              currentOption == 'temp' ||
              currentOption == 'humidity',
          }"
          @click="setWeatherOption('today')"
        >
          当日天气
        </div>
        <div
          class="bar"
          :class="{ 'bar-select': currentOption == 'annualStat' }"
          @click="setWeatherOption('annualStat')"
        >
          年度天气统计占比
        </div>
        <div
          class="bar"
          :class="{ 'bar-select': currentOption == 'monthStat' }"
          @click="setWeatherOption('monthStat')"
        >
          月分布统计占比
        </div>
      </div>

      <!-- 右下角色条温度和湿度色条 -->
      <div
        class="gradients"
        v-show="currentOption == 'temp' || currentOption == 'humidity'"
      >
        <div v-show="currentOption === 'temp'" class="image-scale">
          <div class="scale-text">
            <div class="set-scale-style">50℃</div>
            <div class="set-scale-style">0℃</div>
            <div class="set-scale-style">-50℃</div>
          </div>
          <img src="static/img/weathercn/tempGradients.png" />
        </div>
        <div v-show="currentOption === 'humidity'" class="image-scale">
          <div class="scale-text">
            <div class="set-scale-style">100%</div>
            <div class="set-scale-style">50%</div>
            <div class="set-scale-style">-0%</div>
          </div>
          <img src="static/img/weathercn/humidityGradients.png" />
        </div>
      </div>

      <!-- 右侧底部预警信息 -->
      <div v-if="Object.keys(warning).length !== 0" class="rightBot">
        <div class="titleBar">
          <p :title="warning.text" class="title">{{ warning.title }}</p>
        </div>
        <div
          :style="{ backgroundColor: warning.fillColor }"
          class="warningSign"
        >
          <p class="warningWord">预警中</p>
        </div>
        <div class="warningGrade">
          <div class="left">
            <span :style="{ color: warning.fillColor }" class="color">{{
              warning.level
            }}</span>
            <span class="grade">预警等级</span>
          </div>
          <div class="left">
            <span :style="{ color: warning.fillColor }" class="color">{{
              warning.typeName
            }}</span>
            <span class="grade">预警类型</span>
          </div>
        </div>
        <div class="suggestive">{{ warning.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import defaultConstants from "@/utils/default-constants";
import echarts from "echarts";
import mathUtils from "@/utils/math.js";
import { districtSearch } from "@/utils/mapUtils";

export default {
  data() {
    return {
      runEnv: window.runEnv,
      polygons: [],
      zoom: 5,
      map: {},
      adcodeObjList: [], //对应城市的颜色
      gxWgsPolygon: [],

      markers: [], //  全国的预警信息
      warning: {}, //  地图预警信息
      currentProvince: "广西壮族自治区", //  当前定位的省份
      currentCity: "南宁",
      currentOption: "today",

      cityData: [], //城市数据
      cityWeatherMarkers: [],
      areaData: [], //区域数据
      areaWeatherMarkers: [],
      weatherListCardIsOpen: false, //城市天气卡片是否展开
      isSelectCityMonthStat: false, //月度天气统计选择城市是否展开

      isogramPolygon: [],
      rainIsogramPolygon: [],
      tempIsogramPolygon: [],
      humidityIsogramPolygon: [],
    };
  },
  computed: {
    ...mapState(["token"]),
  },
  mounted() {
    setTimeout(() => {
      this.initMap();
    }, 1300);
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("map1", {
        resizeEnable: true,
        mapStyle: "amap://styles/macaron",
        zoom: 5,
        center: defaultConstants.chinaCenterPoint,
      });

      let self = this;
      districtSearch("广西", (status, result) => {
        // 查询成功时，result即为对应的行政区信息
        let boundaries = result.districtList[0].boundaries;
        boundaries.forEach((path) => {
          new AMap.Polygon({
            map: self.map, // 指定地图对象
            strokeWeight: 2, // 轮廓线宽度
            path: path, //轮廓线的节点坐标数组
            fillOpacity: 0.15, //透明度
            fillColor: "transparent", //填充颜色
            strokeColor: "#256edc", //线条颜色
            zIndex: 999,
          });
        });
      })
      this.initCityAndWeather();
      this.initIsogram("rain");
    },

    async init3dMap() {
      let cityAnnualStatData = await this.getAnnualStat();
      let height = 80000;
      let color = ["#E31A1Dcc", "#FC4E2Bcc", "#FEB24Ccc", "#FFEDA0cc"]; // rgba

      this.map = new AMap.Map("3dMap", {
        resizeEnable: true,
        mapStyle: "amap://styles/dark",
        zoom: 7,
        center: [108.320007, 22.82402],
        pitch: 45,
        viewMode: "3D",
      });

      let self = this;
      var object3Dlayer;

      this.map.plugin("AMap.Object3DLayer", () => {
        object3Dlayer = new AMap.Object3DLayer();
        self.map.add(object3Dlayer);
      });
      this.map.plugin("AMap.Object3D", () => {
        for (let cityName in cityAnnualStatData) {
          let city = cityAnnualStatData[cityName];
          let prismArray = self.makeCityPrismArray(
            city.point.lon,
            city.point.lat
          );

          prismArray.forEach((item, index) => {
            let bounds = [[]];
            item.forEach((lnglat) => {
              bounds[0].push(new AMap.LngLat(lnglat[0], lnglat[1]));
            });
            let prismdata = {
              path: bounds,
              height: height * city.weather[index],
              color: color[index],
              zIndex: 999,
            };

            let prism = new AMap.Object3D.Prism(prismdata);
            prism.transparent = true;

            let day =
              city.weather[index] / 365 > 1 ? city.weather[index] / 365 : "<1";
            let text = new AMap.Text({
              text: city.weather[index] + "天<br/>" + day + "%",
              verticalAlign: "bottom",
              position: [
                mathUtils.accAdd(item[0][0], 0.05),
                mathUtils.accAdd(item[0][1], 0.05),
              ],
              height: height * city.weather[index],
              zIndex: 997,
              style: {
                "background-color": "#2F3E51",
                "text-align": "center",
                width: "38px",
                height: "36px",
                border: "none",
                color: "#BABDC2",
                "font-size": "12px",
              },
            });

            text.setMap(self.map);

            object3Dlayer.add(prism);
          });
        }
      });
    },

    async setWeatherOption(option) {
      if (this.currentOption == "temp" && option == "temp") {
        option = "today";
      }
      if (this.currentOption == "humidity" && option == "humidity") {
        option = "today";
      }
      this.currentOption = option;
      switch (option) {
        case "temp":
        case "humidity":
          this.initIsogram(option);
          break;
        case "today":
          this.initMap();
          break;
        case "monthStat":
          this.getMonthlyStat();
          break;
        case "annualStat":
          this.init3dMap();
          break;
      }
    },

    // 获取城市和天气
    initCityAndWeather() {
      let self = this;
      this.$apis
        .getProvinceWeather(
          {
            province: this.currentProvince,
          },
          this.token
        )
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            result.data.forEach((item) => {
              let data = {
                name: item.name,
                icon: item.icon,
                text: item.text,
                point: item.point,
              };

              let marker = new AMap.Marker({
                position: [item.point.lon, item.point.lat],
                content:
                  '<div style="background: #fff;border-radius: 8px;opacity: 0.8;"><img style="width: 30px;height: 30px;" src="static/img/weathercn/color-64/' +
                  item.icon +
                  '.png" /></div>',
                cursor: "pointer",
                zIndex: 999,
              });
              marker.on("click", function (e) {
                self.jumpCityWeatherForecast(item.name);
              });

              if (item.adm2 == item.name) {
                this.cityData.push(data);
                this.cityWeatherMarkers.push(marker);
              } else {
                this.areaData.push(data);
                this.areaWeatherMarkers.push(marker);
              }
            });
            this.map.add(this.cityWeatherMarkers);
            this.map.setFitView();
            this.bindMapMarker();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    bindMapMarker() {
      let self = this;
      this.map.on("zoomend", function () {
        let zoom = self.map.getZoom();
        if (self.currentOption == "today") {
          if (zoom >= 8) {
            self.map.remove(self.cityWeatherMarkers);
            self.map.add(self.areaWeatherMarkers);
          } else {
            self.map.remove(self.areaWeatherMarkers);
            self.map.add(self.cityWeatherMarkers);
          }
        }
      });
    },

    // 获取年度天气数据
    getAnnualStat() {
      return new Promise((resolve, reject) => {
        this.$apis
          .getAnnualWeatherStat(
            {
              province: this.currentProvince,
            },
            this.token
          )
          .then((res) => {
            let result = res.data;
            if (result.status == 0) {
              let annualData = [];
              for (let cityName in result.data) {
                annualData[cityName] = [];
                annualData[cityName]["weather"] = [0, 0, 0, 0];
                this.cityData.forEach((item) => {
                  if (item.name == cityName) {
                    annualData[cityName]["point"] = item.point;
                  }
                });
                result.data[cityName].forEach((data) => {
                  let weatherIndex = 3;
                  switch (data.key) {
                    case "晴":
                      weatherIndex = 3;
                      break;
                    case "多云":
                      weatherIndex = 2;
                      break;
                    case "阴":
                      weatherIndex = 1;
                      break;
                    default:
                      if (data.key.indexOf("雨") > -1) {
                        weatherIndex = 0;
                      }
                      break;
                  }
                  annualData[cityName]["weather"][weatherIndex] +=
                    data.doc_count;
                });
              }
              resolve(annualData);
            }
          });
      });
    },

    // 制作年度天气数据的棱镜柱体
    makeCityPrismArray(lng, lat) {
      lng = parseFloat(lng);
      lat = parseFloat(lat);
      let width = 0.1;
      let height = 0.1;
      let interval = 0.01;
      //生成四个棱镜的每个棱镜的左上角坐标点
      let prismLngLat1 = [lng, lat];
      let prismLngLat2 = [
        mathUtils.accAdd(mathUtils.accAdd(lng, width), interval),
        lat,
      ];
      let prismLngLat3 = [
        lng,
        mathUtils.accAdd(mathUtils.accAdd(lat, height), interval),
      ];
      let prismLngLat4 = [
        mathUtils.accAdd(mathUtils.accAdd(lng, width), interval),
        mathUtils.accAdd(mathUtils.accAdd(lat, height), interval),
      ];
      let prismLngLatList = [
        prismLngLat1,
        prismLngLat2,
        prismLngLat3,
        prismLngLat4,
      ];
      let result = [];

      //根据这四个棱镜每个左上角的坐标点，算出这四个棱镜的四个点，得出4个棱镜，也就是16个点
      prismLngLatList.forEach((lnglat) => {
        let prismData = [
          [lnglat[0], lnglat[1]],
          [mathUtils.accAdd(lnglat[0], width), lnglat[1]],
          [
            mathUtils.accAdd(lnglat[0], width),
            mathUtils.accAdd(lnglat[1], height),
          ],
          [lnglat[0], mathUtils.accAdd(lnglat[1], height)],
        ];
        result.push(prismData);
      });

      return result;
    },

    //跳转城市天气预报
    jumpCityWeatherForecast(city) {
      let path = "/weatherForecast"
      if (this.runEnv === "portal") {
        path = "/publicWeatherForecast"
      }
      this.$router.push(path + "?city=" + city);
    },

    // 等值线
    initIsogram(type) {
      this.map.remove(this.isogramPolygon);
      this.isogramPolygon = [];
      this.$apis
        .getWeatherIsogram(
          {
            type: type,
          },
          this.token
        )
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            result.data.features.forEach((features) => {
              let geometry = features.geometry;
              let color = features.properties.fill;
              if (geometry.type == "MultiPolygon") {
                geometry.coordinates.forEach((array) => {
                  array.forEach(array2 => {
                    this.handlePolygonArray(array2, color, color);
                  })
                })
              } else {
                geometry.coordinates.forEach((array) => {
                  this.handlePolygonArray(array, color, color);
                });
              }
            });
            this.map.remove(this.cityWeatherMarkers);
            this.map.remove(this.areaWeatherMarkers);
            this.map.add(this.isogramPolygon);
          }
        });
    },
    handlePolygonArray(array, color, strokeColor) {
      let path = [];
      array.forEach((lnglat) => {
        path.push(new AMap.LngLat(lnglat[0], lnglat[1]));
      });
      let polygonObj = new AMap.Polygon({
        path: path,
        fillColor: color, // 多边形填充颜色
        fillOpacity: 0.5,
        strokeWeight: 0.5, // 线条宽度，默认为 1
        strokeColor: strokeColor, // 线条颜色
        zIndex: 10,
      });
      this.isogramPolygon.push(polygonObj);
    },

    //获取月度天气数据
    getMonthlyStat(city) {
      this.isSelectCityMonthStat = false;
      if (city != null) {
        this.currentCity = city;
      }

      function getMonthDay(month) {
        let days = new Date(new Date().getFullYear(), month, 0).getDate();
        return days;
      }

      let month = [];
      let monthData = [[], [], [], []];
      for (let i = 1; i <= 12; i++) {
        let monthText = i < 10 ? "0" + i : i;
        month.push(new Date().getFullYear() + "-" + monthText);
        monthData[0].push(0);
        monthData[1].push(0);
        monthData[2].push(0);
        monthData[3].push(0);
      }

      let seriesColor = ["#FFC616", "#1FD0BE", "#1980FF", "#5166B5"];
      let seriesText = ["晴天", "多云", "阴天", "降雨"];
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        color: seriesColor,
        legend: {
          show: false,
          data: seriesText,
        },
        formatter: function (params) {
          var colorSpan = (color) =>
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';
          let rez = params[0].axisValue + "<br/>";
          params.forEach((item) => {
            let strItem =
              "<span>" +
              colorSpan(item.color) +
              item.seriesName +
              ":" +
              item.value +
              "%</span><br/>";
            rez += strItem;
          });
          return rez;
        },
        xAxis: {
          type: "category",
          axisTick: { show: false },
          data: month,
          axisLabel: {
            rotate: 40,
            align: "center",
            margin: 25,
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          type: "value",
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value} %",
          },
        },
        series: [],
      };

      this.$apis
        .getMonthlyWeatherStat(
          {
            province: this.currentProvince,
            city: this.currentCity,
          },
          this.token
        )
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            for (let weather in result.data) {
              let item = result.data[weather];
              let weatherIndex = 0;
              switch (weather) {
                case "晴":
                  weatherIndex = 0;
                  break;
                case "多云":
                  weatherIndex = 1;
                  break;
                case "阴":
                  weatherIndex = 2;
                  break;
                default:
                  if (weather.indexOf("雨") > -1) {
                    weatherIndex = 3;
                  }
                  break;
              }
              item.forEach((data) => {
                let monthIndex = new Date(data.key).getMonth();
                monthData[weatherIndex][monthIndex] =
                  monthData[weatherIndex][monthIndex] + data.doc_count;
              });
            }

            for (let i = 0; i < 12; i++) {
              let dataDay =
                monthData[0][i] +
                monthData[1][i] +
                monthData[2][i] +
                monthData[3][i];
              let loseDataDay = getMonthDay(i + 1) - dataDay;
              if (loseDataDay > 0 && dataDay > 0 && i < new Date().getMonth()) {
                monthData[0][i] += loseDataDay;
              }
            }

            monthData.forEach((item, weatherIndex) => {
              item.forEach((item, monthIndex) => {
                let monthDay = getMonthDay(monthIndex + 1);
                monthData[weatherIndex][monthIndex] = parseInt(
                  (item / monthDay) * 100
                );
              });
            });

            let series = [];

            monthData.forEach((item, index) => {
              series.push({
                type: "bar",
                name: seriesText[index],
                color: seriesColor[index],
                itemStyle: {
                  normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [20, 20, 0, 0],
                  },
                },
                data: item,
              });
            });
            option.series = series;
            let chartDom = document.getElementById("chart");
            let myChart = echarts.init(chartDom);
            myChart.setOption(option);
          }
        });
    },

    // 获取天气灾害预警
    weatherGetWarning() {
      this.$apis
        .weatherGetWarning({}, this.token)
        .then((res) => {
          this.markers = [];
          let data = res.data.data;
          data.forEach((e, index) => {
            let fillColor = "";
            switch (e.warning[0].level) {
              case "红色":
                fillColor = "rgba(247, 78, 59, 1.0)";
                break;
              case "橙色":
                fillColor = "rgba(255, 186, 57, 1.0)";
                break;
              case "黄色":
                fillColor = "rgba(222, 222, 44, 1.0)";
                break;
              case "蓝色":
                fillColor = "rgba(49, 139, 248, 1.0)";
                break;
              default:
                fillColor = "rgba(255, 255, 255, 1.0)";
                break;
            }
            this.markers[index] = {
              center: [e.lon, e.lat],
              radius: 10,
              zIndex: 999,
              strokeOpacity: 0,
              fillColor: fillColor,
              fillOpacity: 0.6,
              events: {
                click: () => {
                  this.warning = {
                    level: e.warning[0].level,
                    text: e.warning[0].text,
                    title: e.warning[0].title,
                    typeName: e.warning[0].typeName,
                    fillColor: fillColor,
                  };
                },
              },
            };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/iconfont/iconfont.css";
.NavBiu {
  float: left;
}
.weatherMap {
  float: left;
  width: 100%;
  overflow-y: auto;
}
.weatherMap.backend {
  height: calc(100vh - 70px);
}
.weatherMap.portal {
  height: calc(100vh - 50px);
}

.weather-list-card {
  overflow: hidden;
  position: fixed;
  left: 15px;
  bottom: 20px;
  width: 398px;
  height: 352px;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
  padding-left: 50px;
  padding-top: 30px;
  .province-title {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #3c4353;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .city-card-list {
    overflow: hidden;
    width: 282px;
    height: 240px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .city-card {
      width: 302px;
      height: 48px;
      line-height: 48px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .city-name {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
      }
      .city-weather {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .city-card:nth-child(10n + 1) {
      background: #fafbfc;
    }
    .city-card:nth-child(10n + 3) {
      background: #fafbfc;
    }
    .city-card:nth-child(10n + 5) {
      background: #fafbfc;
    }
    .city-card:nth-child(10n + 6) {
      background: #fafbfc;
    }
    .city-card:nth-child(10n + 8) {
      background: #fafbfc;
    }
    .city-card:nth-child(10n + 10) {
      background: #fafbfc;
    }
  }
  .city-card-list-open {
    width: 906px;
  }
  .open-btn {
    position: absolute;
    top: 50%;
    right: 30px;
    cursor: pointer;
  }
}
.rain-color-card {
  position: fixed;
  right: 29px;
  bottom: 20px;
  width: 70px;
  height: 289px;
  background: #323a48;
  border-radius: 8px;
  font-size: 14px;
  color: #b6b6b6;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .color-block {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin-bottom: 1px;
    }
  }
}

.stat-option {
  width: 594px;
  height: 60px;
  background: #fff;
  position: fixed;
  top: 83px;
  right: 30px;
  display: flex;
  justify-content: space-between;
  border-radius: 30px;
  box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
  .bar {
    background: #fff;
    color: #3c4353;
    border-radius: 30px;
    line-height: 60px;
    font-size: 18px;
    padding: 0 40px;
    cursor: pointer;
  }
  .bar-select {
    background: #1182fb;
    color: #ffffff;
    font-weight: bold;
  }
}

.city-weather-icon {
  background: #fff;
  img {
    width: 30px;
    height: 30px;
  }
}

.map-option {
  position: fixed;
  top: 18.7%;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .imgBar {
    height: 42px;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      #ffffff 62%,
      rgba(255, 255, 255, 0.8) 71%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 1px solid #cecece;
    padding-right: 25px;
    line-height: 42px;
    box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 25px;
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
    }
    .signWord {
      margin: 0 15px;
      color: #3c4353;
    }
  }
}
.gradients {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 25px;
  right: 30px;
  width: 96px;
  height: 59%;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
  border-radius: 8px;
  padding: 20px 0;
  .image-scale {
    display: flex;
    align-items: center;
    height: 100%;
    .scale-text {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 100%;
      .set-scale-style {
        width: 39px;
        text-align: center;
        margin-right: 7px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 19px;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }

  img {
    height: 100%;
  }
}

.city-select-container {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  background: #ffffff;
  display: flex;
  padding: 0 50px;
  justify-content: flex-start;
  align-items: center;
  .city-name {
    font-size: 24px;
    font-weight: bold;
    color: #3c4353;
  }
  .change-btn {
    font-size: 14px;
    color: #3c4353;
    cursor: pointer;
  }
}

.city-select-open-card {
  width: 510px;
  height: 211px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 90px;
  left: 15px;
  background: #ffffff;
  box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
  font-size: 14px;
  color: #3c4353;
  .city-select-container {
    padding: 0 30px;
    height: 70px;
  }
  .select-item {
    color: #1980ff;
  }
  .current-city {
    padding-left: 30px;
    line-height: 70px;
  }
  .hint-line {
    width: 100%;
    height: 40px;
    padding: 0 30px;
    background: rgba(16, 101, 231, 0.05);
    line-height: 40px;
    font-size: 12px;
    color: #666666;
  }
  .city-list {
    flex: 1;
    width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    .item {
      width: 13%;
      display: inline-block;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }
}

.city-month-stat-data {
  width: 76.8%;
  height: 392px;
  background: #ffffff;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  position: absolute;
  bottom: 20px;
  right: 15px;
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    height: 51px;
    border-bottom: 1px solid #d8d8d8;
    padding-left: 40px;
    line-height: 51px;
    font-size: 14px;
    color: #3c4353;
    display: flex;
    .city {
      margin-left: 25px;
      margin-right: 80px;
      color: #1980ff;
    }
    .weather-item {
      width: 82px;
      display: flex;
      align-items: center;
      margin-right: 60px;
      .block {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        margin-right: 24px;
      }
    }
  }
  .chart-box {
    flex: 1;
  }
}

.sign {
  color: #cecece;
  font-size: 36px;
  line-height: 70px;
}
.signWord {
  font-size: 14px;
  color: #bebebe;
  margin: 0px;
}
.rightTop {
  width: 400px;
  height: 55%;
  position: absolute;
  right: 7px;
  top: 92px;
  background-color: #fff;
}

.weather {
  padding: 20px 15px;
  background: linear-gradient(45deg, #353f4e, #1c1d1f);
  color: #fff;

  .aqi {
    position: absolute;
    border: 1px solid transparent;
    border-radius: 2px;
    margin-top: 5px;
    padding: 0 3px;
    width: 90px;
    height: 18px;
    line-height: 18px;
    top: 45px;
    left: 18px;
    color: #95b359;
    border-color: #95b359;
  }

  .weather-line {
    display: flex;
    flex-direction: row;
    div {
      width: 33.3%;
      text-align: center;
    }
  }

  .temperature {
    div {
      height: 84px;
      line-height: 84px;
    }
  }
}

.annual-stat-temp-control {
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 60px;
  position: absolute;
  bottom: 40px;
  right: 30px;
  font-size: 14px;
  color: #b6b6b6;
  .hint {
    font-size: 12px;
    margin-bottom: 10px;
    text-align: right;
  }
  .block-list {
    display: flex;
    width: 100%;
    height: 25px;
  }
  .text-list {
    display: flex;
    margin-top: 10px;
  }
  .block {
    flex: 1;
    height: 25px;
    text-align: center;
  }
}

.weather-introduce {
  margin: 20px 0;
  padding-left: 10px;
}

.week-weather {
  display: flex;
  text-align: center;
  div {
    font-size: 14px;
    padding: 16px 0;
  }
  .week-t {
    text-align: center;
    span {
      border-bottom: 2px solid #95b359;
      margin-right: 10px;
    }
    width: 66px;
    background: url("/static/img/weathercn/color-64/100.png") no-repeat center
      right;
    background-size: 21px 21px;
  }

  .week-scope {
    width: 240px;
    span {
      display: inline-block;
      width: 120px;
      height: 5px;
      background: linear-gradient(to right, #e68989, #9aa6ba);
      transform: translateY(-3px);
      border-radius: 3px;
    }
  }

  .week-icon {
    width: 21px;
    background: url("https://a.hecdn.net/img/plugin/190516/icon/c/101n.png")
      no-repeat center right;
    background-size: 21px 21px;
  }
}

.rightBot {
  width: 400px;
  position: absolute;
  right: 7px;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  display: grid;
  border-top: 1px solid #000;
  .titleBar {
    .title {
      font-size: 18px;
      color: #000000;
    }
  }
  .warningSign {
    width: 148px;
    height: 54px;
    background-color: #ffd20d;
    border-radius: 6px;
    margin: 10px auto;
    .warningWord {
      font-size: 28px;
      color: #3c4353;
      text-align: center;
      line-height: 54px;
      margin: 0;
    }
  }
  .warningGrade {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    .left {
      width: 100px;
      span {
        display: block;
      }
      .color {
        color: #ffb400;
        font-size: 28px;
      }
      .grade {
        font-size: 14px;
        color: #b6b6b6;
      }
    }
  }
  .suggestive {
    font-size: 12px;
    color: #000000;
    margin: 10px auto;
    padding: 0 10px;
  }
}
</style>
