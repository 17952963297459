<template>
  <div
    class="liteMonitor"
    @mouseenter="showMoreInformation"
    @mouseleave="hideMoreInformation"
  >
    <span v-if="category_name === '无人机'">
      <div
        style="width: 100%; height: 100%; position: absolute; z-index: 9999"
        @click="openSingleMonitor"
      ></div>
      <div class="monitorBody" @click="openSingleMonitor">
        <!-- <slot name="singleMonitor"></slot> -->
        <singleMonitor
          :ref="software_code"
          :software_code="software_code"
          :order_id="mission_id"
          :isImageMap="isImageMap"
          :brandName="brandName"
          :listDeviceData="listDeviceData"
          @setFlyArea="setFlyArea"
          @setFeimaDroneInfo="setFeimaDroneInfo"
          :moreScreen="moreScreen"
        />
      </div>
      <div class="Footer">
        <div class="monitorFooter">
          <div class="left">
            <img
              :src="device_data.device_brands_icon"
              alt=""
              style="height: 43px; margin-left: 10px"
            />
          </div>
          <div class="right">
            <div class="area">
              <img src="@/assets/img/homeDialog/area.png" alt="" />
              <span class="area_data" :title="flyArea">{{ Number(flyArea).toFixed(1) }}</span>
              <span style="font-size: 13px" class="flyarea">已飞面积</span>
            </div>
            <div class="location">
              <img src="@/assets/img/homeDialog/location.png" alt="" />
              <span
                style="font-size: 13px"
                :title="province + ' ' + city + ' ' + area"
                >{{ province + " " + city + " " + area }}</span
              >
            </div>
          </div>
        </div>

        <div
          class="droneInformation"
          v-if="brandName === '飞马'"
          ref="droneInformation"
        >
          <div>
            <div class="droneInformationData" :title="holder_user_data.name">
              {{ holder_user_data.name }}
            </div>
            <div class="droneInformationTitle">用户名</div>
          </div>
          <div>
            <div class="droneInformationData" :title="holder_user_data.mobile">
              {{ holder_user_data.mobile }}
            </div>
            <div class="droneInformationTitle">手机号</div>
          </div>
          <div>
            <div class="droneInformationData" :title="software_code">
              {{ software_code }}
            </div>
            <div class="droneInformationTitle">飞机序列号</div>
          </div>
        </div>
      </div>
      <span v-if="singleDialogVisible">
        <dialog-single-monitor
          :terminal="terminal"
          :listDeviceData="userData"
          source="two"
          @closeDialogSingleMonitor="closeDialogSingleMonitor"
        />
      </span>
    </span>
    <span v-else>
      <div style="margin: 15px">
        <img
          :src="
            deviceIcon[
              brandName === '监控模块'
                ? deviceData._source.associated_device_type
                : deviceData._source.brand_name
            ]
          "
          alt=""
          style="width: 100%"
        />
      </div>
      <div class="deviceInformation">
        <div class="text">{{ deviceData._source.unit_name }}</div>
        <div>{{ detailedAddress }}</div>
      </div>
    </span>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DialogSingleMonitor from "@/components/monitorPlane/dialogSingleMonitor.vue";

import singleMonitor from "@/components/monitorPlane/singleMonitor.vue";
import * as turf from "@turf/turf";
export default {
  name: "liteMonitor",
  computed: {
    ...mapState(["token"]),
  },
  components: {
    singleMonitor,
    DialogSingleMonitor,
  },
  props: {
    uuid: {
      type: String,
      default: "",
    },
    software_code: {
      type: String,
      default: "",
    },
    brandName: {
      type: String,
      default: "",
    },
    mission_id: {
      type: String,
      default: "",
    },
    category_name: {
      type: String,
      default: "",
    },
    deviceIcon: {
      type: Object,
    },
    deviceData: {
      type: Object,
    },
  },
  data: function () {
    return {
      moreScreen: false,
      listDeviceData: {},
      userData: {},
      device_data: {}, //无人机信息
      terminal: {},
      flyArea: 0,
      province: "",
      city: "",
      area: "",
      holder_user_data: {}, //使用者信息
      allData: {}, //所有数据
      singleDialogVisible: false,
      detailedAddress: "",
      isImageMap: false
    };
  },
  mounted() {
    this.getDeviceDetailInfo(this.software_code);
  },
  methods: {
    // 获取无人机信息
    getDroneInfo(drone, planeType = "feima", isHistory = false, deviceLayer) {
      const param = {
        ...drone,
        showEnterButton: true,
        software_code:
          drone.uas_serial_id || drone.uavNumber || drone.software_code,
        planeType: planeType,
        isHistory: isHistory,
        deviceLayer: deviceLayer,
        // OrderID: drone.OrderID
      };
      this.getDeviceDetailInfo(param);
    },
    // 获取设备详细信息
    getDeviceDetailInfo(software_code) {
      this.$apis
        .getDeviceDetailInfo(
          {
            software_code,
          },
          this.token
        )
        .then((data) => {
          this.device_data = data.data.data.device_data;
          this.terminal = data.data.data;
          this.terminal["droneInfo"] = {};
          this.terminal["droneInfo"]["brand_name"] = this.brandName;
          this.terminal["droneInfo"]["isHistory"] = false;
          this.terminal["droneInfo"]["software_code"] = this.software_code;
          this.terminal["droneInfo"]["mission_id"] = this.mission_id;

          this.terminal["missionId"] = this.mission_id;
          this.terminal["planeType"] = this.terminal["monitor_type"];

          this.holder_user_data = data.data.data.msg_data.holder_user_data;

          this.userData = {
            ...data.data.data.holder_user_data,
          };
          this.toDetail(this.device_data);
          this.findLocation([
            data.data.data.device_data.last_point.lon,
            data.data.data.device_data.last_point.lat,
          ]);
        });
    },
    //逆地址查找地址
    findLocation(lnglat) {
      let that = this;
      var locationText;
      getAddress(lnglat, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          that.detailedAddress = result.regeocode.formattedAddress;
          locationText = result.regeocode.addressComponent;
          that.city = locationText.city;
          that.province = locationText.province;
          that.area = locationText.district;
          that.terminal.device_data.last_point["city"] = locationText.city;
          that.terminal.device_data.last_point["area"] =
            locationText.district;
          that.terminal.device_data.last_point["province"] =
            locationText.province;
        }
      });
    },
    setFlyArea(data) {
      this.flyArea = data.toFixed(1);
    },
    setFeimaDroneInfo(data) {
      // console.log(data);
    },
    // 单屏监控
    toDetail(res) {
      this.allData = res;
      if (this.category_name === "无人机") {
        if (res.brand_name !== "飞马" && res.brand_name !== "大疆") {
          setTimeout(() => {
            this.$refs[this.software_code].linkWebsocket(false, [0, 0]);
          }, 800);
        } else {
          setTimeout(() => {
            this.$refs[this.software_code].openFeimaDetail(this.mission_id);
          }, 800);
        }
      }
    },
    // 飞马无人机显示更多数据
    showMoreInformation() {
      if (this.allData.brand_name === "飞马") {
        this.$refs.droneInformation.style.height = "43px";
      }
    },
    // 移出隐藏
    hideMoreInformation() {
      if (this.allData.brand_name === "飞马") {
        this.$refs.droneInformation.style.height = "0px";
      }
    },
    openSingleMonitor() {
      console.log("点开");
      this.$confirm("是否打开单屏监控?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.singleDialogVisible = true;
        })
        .catch(() => {});
    },
    closeDialogSingleMonitor() {
      this.singleDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.liteMonitor {
  height: 100%;
  width: 100;
  background-color: #fff;
  position: relative;
  .monitorBody {
    height: 100%;
    width: 100%;
  }
  .Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    .droneInformation {
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;
      background: #091b32;
      border-radius: 0px 0px 12px 12px;
      opacity: 0.89;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .droneInformationTitle {
        font-size: 16px;
        transform: scale(0.5);
        // color: ;
      }
      .droneInformationData {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #ffba00;
        text-align: center;
      }
    }
  }
  .monitorFooter {
    // position: absolute;
    width: 100%;
    height: 56px;
    line-height: 56px;
    background: #0c2039;
    border-radius: 0px 0px 7px 7px;
    // bottom: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    .left {
      height: 56px;
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      width: 80%;
      // justify-content: space-around;
      align-items: center;
      .area {
        margin-left: 4%;
        // margin-right: 4%;
        width: 150px;
        display: flex;
        align-items: center;
        img {
          position: relative;
          // top: 5px;
          height: 24px;
        }
        .area_data {
          font-family: "DIN";
          font-weight: bold;
          color: #ffba00;
          width: 50px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          // margin: 0 8px;
        }
        .flyarea {
          width:55px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
      .location {
        width: 47%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        img {
          position: relative;
          top: 3px;
          margin-right: 5px;
        }
      }
    }
  }
  .deviceInformation {
    margin: 50px 20px;
    font-size: 16px;
    line-height: 25px;
    .text {
      margin-bottom: 15px;
    }
  }
}
</style>
