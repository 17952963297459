import { getAddress as getAddressApi } from './service'

/**
 * GetAddressCallback
 * @callback GetAddressCallback
 * @param {string} status
 * @param {Object} result result.info === "OK") {
 * @param {string} result.info ok        that.detailedAddress = result.regeocode.formattedAddress;
 * @param {Object} result.regeocode
 * @param {string} result.formattedAddress 格式化的地址
 * @param {Object} result.addressComponent
 * @param {string} result.addressComponent.province 省
 * @param {string} result.addressComponent.city 市
 * @param {string} result.addressComponent.district 区
 * @param {string} result.addressComponent.street 街道
 * @param {string} result.addressComponent.streetNumber 街道号
 */

/**
 * @param {Array<Number>} lnglat
 * @param {GetAddressCallback} callback
 */
export async function getAddress(lnglat, callback) {
  const res = await getAddressApi(lnglat)
  callback(res.data.status === '0' ? 'complete' : 'error', resultAdapterAMap(res.data))
  return resultAdapterAMap(res.data)
}

function resultAdapterAMap(data) {
  const toAddressComponent = ({addressComponent, location}) => {
    return {
      country: addressComponent.nation,
      province: addressComponent.province,
      city: addressComponent.city,
      citycode: addressComponent.city_code,
      district: addressComponent.county,
      township: addressComponent.town,
      towncode: addressComponent.town_code,
      streetNumber: {
        street: addressComponent.road,
        number: addressComponent.street_number,
        location: `${location.lon},${location.lat}`,
        direction: addressComponent.address_position,
        distance: addressComponent.address_distance
      },
      adcode: addressComponent.adcode,
      location: `${location.lon},${location.lat}`,
      level: addressComponent.level
    }
  }
  return {
    info: data.msg.toUpperCase(),
    regeocode: {
      location: data.result.location,
      addressComponent: toAddressComponent(data.result),
      formattedAddress: data.result.formatted_address
    }
  }
}
