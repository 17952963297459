<template>
  <div class="container" v-if="mapKey">
    <el-amap
      id="topNav"
      :plugin="plugin"
      :events="events"
      :zoom="zoom"
      :center="center"
      class="amap-box"
      :vid="'amap-vue'"
      :amap-manager="amapManager"
    >
      <!-- 在线终端轨迹 天地图坐标系显示 -->
      <span v-if="!isGcjPosition">
        <span v-for="(item, index) in onlineTerminal">
          <!-- 在线终端轨迹 -->
          <el-amap-polyline
            v-for="(trackItem, yindex) in item.track.path"
            :key="yindex + 'wgsOnlineTerminalTrack'"
            stroke-color="#ffc300"
            :editable="false"
            :stroke-weight="2"
            :visible="item.track.visible"
            :path="wgsOnlineTerminal[index].track.path[yindex]"
            :events="trackItem.events"
          ></el-amap-polyline>
        </span>
      </span>

      <!-- 在线终端 高德坐标系显示 -->
      <span v-if="isGcjPosition">
        <span v-for="(item, index) in onlineTerminal">
          <!-- 在线终端轨迹 -->
          <el-amap-polyline
            v-for="(trackItem, yindex) in item.track.path"
            :key="yindex + 'onlineTerminalTrack'"
            stroke-color="#ffc300"
            :editable="false"
            :stroke-weight="2"
            :visible="item.track.visible"
            :path="trackItem"
            :events="trackItem.events"
          ></el-amap-polyline>
        </span>
      </span>

      <!-- 历史终端轨迹 天地图坐标系-->
      <span v-if="!isGcjPosition">
        <span v-for="(item, index) in historyTerminal">
          <el-amap-polyline
            v-for="(trackItem, yindex) in item.track.path"
            :key="yindex + 'wgsHistoryTerminalTrack'"
            stroke-color="#ffc300"
            :editable="false"
            :stroke-weight="2"
            :visible="item.track.visible"
            :path="wgsHistoryTerminal[index].track.path[yindex]"
            :events="trackItem.events"
          ></el-amap-polyline>
        </span>
      </span>

      <!-- 历史终端轨迹 高德坐标系-->
      <span v-if="isGcjPosition">
        <span v-for="(item, index) in historyTerminal">
          <el-amap-polyline
            v-for="(trackItem, yindex) in item.track.path"
            :key="yindex + 'historyTerminalTrack'"
            stroke-color="#ffc300"
            :editable="false"
            :stroke-weight="2"
            :visible="item.track.visible"
            :path="trackItem"
            :events="trackItem.events"
          ></el-amap-polyline>
        </span>
      </span>
    </el-amap>

    <div v-show="monitorType == 'list'">
      <!-- 调度中心面板 -->
      <div class="backend-panel" v-if="runEnv == 'backend'">
        <div class="setup" @click="openBar" />
        <!-- 在线设备 -->
        <div class="online" @click="online">
          <span class="title">在线设备</span>
          <span class="count">{{ onlineCount }}</span>
        </div>
        <!-- 历史设备 -->
        <div class="history" @click="historyBtn">
          <span class="title">历史设备</span>
          <span class="count">{{ historyCount }}</span>
        </div>
        <!-- 在线设备弹框 -->
        <div v-show="onlineShow" class="onlineBox">
          <div class="feima" @click="feimaOnline">
            <img class="userIcon" src="@/assets/img/home／feima／on.png" />
            <div class="feimaTit">
              <span class="usertype">飞马</span>
              <span class="count">{{ feimaOnlineCount }}</span>
            </div>
          </div>
          <div class="currency">
            <img class="userIcon" src="@/assets/img/home／general／on.png" />
            <div class="feimaTit">
              <span class="usertype">通用</span>
              <span class="count">{{ commonOnlineCount }}</span>
            </div>
          </div>
          <div class="terminal">
            <img class="userIcon" src="@/assets/img/home／phone／on.png" />
            <div class="feimaTit">
              <span class="usertype">终端</span>
              <span class="count">{{ terminalOnlineCount }}</span>
            </div>
          </div>
        </div>
        <!-- 历史设备弹框 -->
        <div v-show="historyShow" class="historyBox">
          <div class="feima">
            <img class="userIcon" src="@/assets/img/home／feima／off.png" />
            <div class="feimaTit">
              <span class="usertype">飞马</span>
              <span class="count">{{ feimaHistoryCount }}</span>
            </div>
          </div>
          <div class="currency">
            <img class="userIcon" src="@/assets/img/home／general／off.png" />
            <div class="feimaTit">
              <span class="usertype">通用</span>
              <span class="count">{{ commonHistoryCount }}</span>
            </div>
          </div>
          <div class="terminal">
            <img class="userIcon" src="@/assets/img/home-phone-off.png" />
            <div class="feimaTit">
              <span class="usertype">终端</span>
              <span class="count">{{ terminalHistoryCount }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 门户面板 -->
      <div class="portal-panel" v-if="runEnv == 'portal'">
        <div class="setup" @click="openBar" />
        <div class="online" @click="online">
          <i class="iconfont flying">&#xe64f;</i>
          <span v-if="token" class="bottBar">
            <p class="count">{{ onlineCount }}</p>
            <p class="title">在线设备</p>
          </span>
        </div>
        <div class="history" @click="historyBtn">
          <i class="iconfont flying">&#xe64e;</i>
          <span v-if="token" class="bottBar">
            <p class="count">{{ historyCount }}</p>
            <p class="title">历史设备</p>
          </span>
        </div>
        <div v-show="onlineShow" class="onlinedeta">
          <div class="line" @click="feimaOnline">
            <span class="feima">{{ feimaOnlineCount }}</span>
            <i class="iconfont circelone">&#xe652;</i>
            <span class="brand">飞马无人机</span>
          </div>
          <div class="line">
            <span class="tongyong">{{ commonOnlineCount }}</span>
            <i class="iconfont circeltwo">&#xe651;</i>
            <span class="brand">通用无人机</span>
          </div>
          <div class="line">
            <span class="yidong">{{ terminalOnlineCount }}</span>
            <i class="iconfont circelthree">&#xe653;</i>
            <span class="brand">移动终端</span>
          </div>
        </div>
        <div v-show="historyShow" class="historydeta">
          <div class="line">
            <span class="feima">{{ feimaHistoryCount }}</span>
            <i class="iconfont circelone">&#xe652;</i>
            <span class="brand">飞马无人机</span>
          </div>
          <div class="line">
            <span class="tongyong">{{ commonHistoryCount }}</span>
            <i class="iconfont circeltwo">&#xe651;</i>
            <span class="brand">通用无人机</span>
          </div>
          <div class="line">
            <span class="yidong">{{ terminalHistoryCount }}</span>
            <i class="iconfont circelthree">&#xe653;</i>
            <span class="brand">移动终端</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 右上角筛选面板 -->

    <div v-show="monitorType == 'list'">
      <setBar
        ref="setup"
        :style="{ right: runEnv == 'backend' ? '22%' : '44px' }"
        :feimaOnlineCount="feimaOnlineCount"
        :feimaHistoryFliterCount="feimaHistoryFliterCount"
        :commonOnlineCount="commonOnlineCount"
        :commonHistoryFliterCount="commonHistoryFliterCount"
        :terminalOnlineCount="terminalOnlineCount"
        :terminalHistoryFliterCount="terminalHistoryFliterCount"
        @changeOnlineTermianalTrackVisible="changeOnlineTermianalTrackVisible"
        @changeHistoryTerminal="changeHistoryTerminal"
        @changeHistoryFeima="changeHistoryFeima"
        @changeHistoryCommon="changeHistoryCommon"
        @guideMap="guideMap"
        @skyMap="skyMap"
        @imageTile="imageTile"
        @hideOrshowMassMaker="hideOrshowMassMaker"
      />
    </div>

    <!-- 设备相关信息弹出面板 -->
    <div class="historyTerminaldialog">
      <el-dialog
        :modal="false"
        :visible.sync="historyTerminaldialogVisible"
        v-if="historyTerminaldialogVisible"
        :show-close="true"
        :title="terminalDialogTitle"
        v-dialogDrag
      >
        <span slot="title" class="dialog-header">
          <span v-if="terminal.dialogType === 'drone'">
            <span class="dia_title" v-show="monitorType === 'list'"
              >任务信息</span
            >
            <span class="dia_title" v-show="monitorType === 'point'"
              >设备信息</span
            >
            <span v-if="terminal.dialogType === 'drone'">
              <span class="dia_name" v-if="terminal.droneInfo.brand_name !== '彩虹'"> 其他无人机 </span>
              <span class="dia_name" v-else> 彩虹无人机 </span>

              <span
                class="trajectoryBar"
                @click="lookParams"
                v-show="
                  terminal.planeType === 'feima' && monitorType === 'list'
                "
              >
                <img
                  src="@/assets/img/homeDialog/moreInformation.png"
                  style="cursor: pointer"
                />
              </span>
            </span>
          </span>
          <span v-else class="dia_title">
            设备信息
            <div class="trajectoryBar" v-show="monitorType === 'list'">
              <span class="trajectory">移动轨迹</span>
              <el-switch
                class="switchTurn"
                @change="showThisTrack"
                v-model="isShowThisTrack"
              ></el-switch>
            </div>
          </span>
          <div v-else-if="terminal.dialogType === 'params'">
            <span
              class="dia_name"
              style="cursor: pointer"
              @click="returnDronne"
            >
              返回
            </span>
          </div>
        </span>
        <dtuPop
          ref="dtupop"
          :terminal="terminal"
          :trackButtonValue="trackButtonValue"
          :monitorType="monitorType"
          @setLocationInMap="setLocationInMap"
        ></dtuPop>
      </el-dialog>
    </div>

    <!-- 设备相关信息弹出面板 -->
    <!-- <div v-show="onlineVisible" class="particulars" id="dtuPopDialog"> -->
  </div>
</template>
<script type="text/javascript" src='https://a.amap.com/jsapi_demos/static/citys.js'></script>
<script>
import { mapState, mapMutations } from "vuex";
import defaultConstants from "@/utils/default-constants";
import setBar from "@/components/setup.vue";
import timeJs from "@/utils/time";
import { AMapManager } from "vue-amap";
import { number } from "echarts/lib/export";
import { connect } from "echarts/lib/echarts";
import coordtransform from "@/utils/transform.js";
import { districtSearch } from "@/utils/mapUtils";

let amapManager = new AMapManager();
export default {
  props: {
    monitorType: {
      type: String,
      default: "list",
    },
  },
  components: {
    setBar,
  },
  data() {
    const self = this;
    return {
      mapKey: true,

      historyTerminaldialogVisible: false, //历史终端弹框
      onlineVisible: false, //在线终端弹框
      locationMarker: [], //定位坐标点
      locationMarkerLayer: "", // 定位坐标点实例图层
      initShowLocation: true, // 初始化显示定位坐标 ，只显示一次
      img: require("@/assets/svg/monitor_common.svg"),
      runEnv: window.runEnv,
      zoom: 7,
      center: defaultConstants.mapDefaultLocation,
      elMap: {},
      tiandituLayer: {},
      googleLayer: {},
      tianditutext: {},
      gxGcjPolygon: [],
      gxWgsPolygon: [],
      gxPolygon: [],
      events: {
        init(e, mapStyle) {
          self.tiandituLayer = new AMap.TileLayer({
            // getTileUrl:
            //   "http://t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=vec_c&tk=130e98b8e0e6090e16d6ba3e40662de3&x=[y]&y=[x]&l=[z]",
            getTileUrl:
              "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=vec_w&tk=130e98b8e0e6090e16d6ba3e40662de3&x=[x]&y=[y]&l=[z]",
            zIndex: 100,
          });
          // 天地图文字图层
          self.tianditutext = new AMap.TileLayer({
            getTileUrl:
              "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=cva_w&tk=130e98b8e0e6090e16d6ba3e40662de3&x=[x]&y=[y]&l=[z]",
            zIndex: 100,
          });

          self.googleLayer = new AMap.TileLayer({
            getTileUrl:
              "//wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
            zIndex: 99,
            zooms: [3, 20],
          });

          self.elMap = e;

          e.setMapStyle("amap://styles/dark");

          // 搜索所有省/直辖市信息
          districtSearch("广西", (status, result) => {
            // 查询成功时，result即为对应的行政区信息
            self.gxGcjPolygon = result.districtList[0].boundaries.map(polygons=> polygons.map(path=>
              path.map(item=>
                coordtransform.wgs84togcj02(
                  item[0],
                item[1]
                ))
              )
            );
            self.handlePolygon(self.gxGcjPolygon);

            // console.log(self.gxGcjPolygon);
            if (self.gxWgsPolygon.length === 0) {
              self.gxGcjPolygon.forEach((items, index) => {
                self.gxWgsPolygon[index] = [];
                items.forEach((data, i) => {
                  self.gxWgsPolygon[index].push(
                    self.gcj_decrypt_exact(data.lat, data.lng)
                  );
                });
              });
            }
          });
        },
      },
      handlePolygon(result) {
        let bounds = result;
        self.gxPolygon = [];
        if (bounds.length) {
          for (let i = 0, l = bounds.length; i < l; i++) {
            //生成行政区划polygon
            self.gxPolygon.push(
              new AMap.Polygon({
                map: self.elMap, // 指定地图对象
                strokeWeight: 2, // 轮廓线宽度
                path: bounds[i], //轮廓线的节点坐标数组
                fillOpacity: 0.15, //透明度
                fillColor: "transparent", //填充颜色
                strokeColor: "#256edc", //线条颜色
              })
            );
          }
          // 地图自适应
          // self.elMap.setFitView()
        }
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          showMarker: false,
          panToLocation: false,
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // self.center = [result.position.lng, result.position.lat];
                  self.locationMarker = [
                    result.position.lng,
                    result.position.lat,
                  ];
                }
              });
            },
          },
        },
      ],
      polyline: {
        path: [],
        events: {
          end: (e) => {
            const newPath = e.target
              .getPath()
              .map((point) => [point.lng, point.lat]);
          },
        },
        editable: false,
      },
      onlineShow: false,
      historyShow: false,
      terminal: {},
      timer: null, //飞马和终端在线设备轮询器
      feimaOnlineCount: 0, //飞马在线设备数
      feimaHistoryCount: 0, //飞马总历史架次数
      feimaHistoryFliterCount: 0, //飞马筛选历史架次数
      commonOnlineCount: 0, //通用设备在线数
      commonHistoryCount: 0, //通用历史架次数
      commonHistoryFliterCount: 0, //通用筛选历史架次数
      terminalOnlineCount: 0, //终端设备在线数
      terminalHistoryCount: 0, //终端历史架次数
      terminalHistoryFliterCount: 0, //终端筛选历史架次数
      onlineTerminal: [], //在线终端坐标点集合
      historyTerminal: [], //历史终端坐标点集合
      onlineFeima: [], //在线飞马坐标点集合
      historyFeima: [], //历史飞马坐标点集合
      onlineCommon: [], //在线通用坐标点集合
      historyCommon: [], //历史通用坐标点集合
      markers: [],
      history: [],
      page: 1,
      historyData: [],
      onlineData: [],
      timer: null,
      amapManager,
      // 备份存储坐标数据，判断是否需要重新更新地图海量点数据
      tem_onlineCommon: [],
      tem_onlineFeima: [],
      tem_historyFeima: [],
      tem_onlineTerminal: [],
      tem_historyTerminal: [],
      // 存储地图标记点对象，下标0，2，4数据对应飞马在线，通用在线，终端在线标记点数据，1，3，5对应历史飞马，历史通用，历史终端
      masskersObj: [],
      PI: 3.14159265358979324,
      x_pi: (3.14159265358979324 * 3000.0) / 180.0,
      delta: function (lat, lon) {
        var a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
        var ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
        var dLat = this.transformLat(lon - 105.0, lat - 35.0);
        var dLon = this.transformLon(lon - 105.0, lat - 35.0);
        var radLat = (lat / 180.0) * this.PI;
        var magic = Math.sin(radLat);
        magic = 1 - ee * magic * magic;
        var sqrtMagic = Math.sqrt(magic);
        dLat =
          (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * this.PI);
        dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * this.PI);
        return { lat: dLat, lon: dLon };
      },

      // wgs84坐标
      wgsOnlineTerminal: [], //在线终端坐标点集合
      wgsHistoryTerminal: [], //历史终端坐标点集合
      wgsOnlineFeima: [], //在线飞马坐标点集合
      wgsHistoryFeima: [], //历史飞马坐标点集合
      wgsOnlineCommon: [], //在线通用坐标点集合
      wgsHistoryCommon: [], //历史通用坐标点集合
      // 存储已经显示的图层
      showLayer: [0, 2, 4, 6, 8, 10],

      // 是否使用高德地图坐标系显示图层
      isGcjPosition: true,

      //在线终端在页面的位置像素
      offsetY: 0,
      offsetX: 0,

      // 终端弹窗轨迹按钮
      trackButtonValue: false,
      isShowThisTrack: false, //显示轨迹

      deviceMarker: "", //设备弹窗点击定位时的点标记
    };
  },
  methods: {
    // 地图定位完成后加载定位图标
    setInitLocation() {
      let that = this;
      if (this.locationMarker.length > 0 && this.initShowLocation) {
        this.setLocationMarker();
        this.initShowLocation = false;
      } else {
        setTimeout(function () {
          that.setInitLocation();
        }, 2000);
      }
    },
    // 天地图与高德切换地图时定位点坐标进行转换后，重新设置标记点
    setLocationMarker() {
      if (this.locationMarker.length > 0) {
        if (this.locationMarkerLayer !== "") {
          this.locationMarkerLayer.setMap(null);
        }
        let map = amapManager.getMap(),
          locationPosition;
        let endIcon = new AMap.Icon({
          size: new AMap.Size(23, 23),
          image: "//webapi.amap.com/theme/v1.3/markers/b/loc.png",
          imageSize: new AMap.Size(23, 23),
          imageOffset: new AMap.Pixel(0, 0),
        });
        if (!this.isGcjPosition) {
          locationPosition = this.gcj_decrypt_exact(
            this.locationMarker[1],
            this.locationMarker[0]
          );
        } else {
          locationPosition = this.locationMarker;
        }
        this.locationMarkerLayer = new AMap.Marker({
          position: locationPosition,
          icon: endIcon,
          zIndex: 112,
          offset: new AMap.Pixel(-11.5, -11.5),
        });
        map.add(this.locationMarkerLayer);
      }
    },
    // 隐藏或显示标记点
    hideOrshowMassMaker(data) {
      let map = amapManager.getMap();
      let index = [];
      switch (data.name) {
        case "onlineFeima":
          index = [0];
          break;
        case "historyFeima":
          index = [1];
          break;
        case "onlineCommon":
          index = [2];
          break;
        case "historyCommon":
          index = [3];
          break;
        case "onlineTerminal":
          index = [4];
          break;
        case "historyTerminal":
          index = [5];
          break;
        case "feima":
          index = [0, 1];
          break;
        case "common":
          index = [2, 3];
          break;
        case "terminal":
          index = [4, 5];
          break;
      }
      let makersLayer;
      if (data.markerVisible) {
        for (let i in index) {
          let indexData = this.showLayer.indexOf(index[i]);
          this.showLayer.splice(indexData, 1);
          let wgsIndexData = this.showLayer.indexOf(index[i] + 6);
          this.showLayer.splice(wgsIndexData, 1);
          if (this.isGcjPosition) {
            makersLayer = this.masskersObj[index[i]];
          } else {
            makersLayer = this.masskersObj[index[i] + 6];
          }
          if (makersLayer !== undefined && makersLayer.length !== 0) {
            if (makersLayer.length >= 0) {
              for (let index in makersLayer) {
                makersLayer[index].setMap(null);
              }
            } else {
              makersLayer.setMap(null);
            }
          }
        }
      } else {
        this.showLayer = this.showLayer.concat(index);
        this.showLayer = this.showLayer.concat([index[0] + 6]);
        let makersLayer;
        for (let i in index) {
          if (this.isGcjPosition) {
            makersLayer = this.masskersObj[index[i]];
          } else {
            makersLayer = this.masskersObj[index[i] + 6];
          }
          if (makersLayer !== undefined && makersLayer.length !== 0) {
            if (makersLayer.length >= 0) {
              for (let index in makersLayer) {
                // makersLayer[index].show();
                makersLayer[index].setMap(map);
              }
            } else {
              // makersLayer.show();
              makersLayer.setMap(map);
            }
          }
        }
      }
    },
    // 在线终端弹窗位置
    setOnlineFeimaPup(lng, lat) {
      const that = this;
      // let map = amapManager.getMap();
      // let lnglat = new AMap.LngLat(lng, lat);
      // let pixel = map.lngLatToContainer(lnglat); //
      // that.offsetX = pixel.x;
      // that.offsetY = pixel.y;
      // let dom = document.getElementById("dtuPopDialog");
      // let top = that.offsetY - 16 + "px";
      // let left = that.offsetX + "px";
      // // 根据经纬度得到页面距离，定位在线终端弹窗位置
      // if (that.offsetY < 430) {
      //   dom.style.top = top;
      //   dom.style.left = left;
      // } else {
      //   dom.style.top = that.offsetY - 425.86 + "px";
      //   dom.style.left = left;
      // }
      // that.onlineVisible = true;
      // this.setL
      // this.getLocationUser();
    },
    // 在线标记点，图为svg格式
    setOnlineMakers(
      data,
      photoUrl,
      number,
      zIndex = 100,
      setMap = true,
      location = false
    ) {
      const that = this;
      // that.onlineVisible = false;
      if (
        this.masskersObj[number] !== undefined &&
        this.masskersObj[number].length > 0
      ) {
        for (let i in this.masskersObj[number]) {
          this.masskersObj[number][i].setMap(null);
        }
      }
      this.masskersObj[number] = [];
      let map = amapManager.getMap();
      // 判断地图实例是否可以获取得到，否则两秒后重新执行本函数获取到地图实例后再进行海量点渲染。
      if (map === null || map === undefined) {
        setTimeout(function () {
          that.setOnlineMakers(
            data,
            photoUrl,
            number,
            zIndex,
            setMap,
            location
          );
        }, 2000);
      } else {
        let endIcon = new AMap.Icon({
          size: new AMap.Size(32, 32),
          image: photoUrl,
          imageSize: new AMap.Size(32, 32),
          imageOffset: new AMap.Pixel(0, 0),
        });
        for (let i = 0, marker; i < data.length; i++) {
          marker = new AMap.Marker({
            position: data[i].position,
            icon: endIcon,
            zIndex: zIndex,
            offset: new AMap.Pixel(-16, -16),
          });
          if (location) {
            marker.on("click", function (e) {
              data[i].events.click();
              // let obj = arr[i];
              // obj.track = track;
              // this.getLocationUser(obj); //4456
              // that.setOnlineFeimaPup(data[i].position[0], data[i].position[1]);
            });
          } else {
            marker.on("click", function (e) {
              data[i].events.click();
            });
          }
          this.masskersObj[number].push(marker);
          if (setMap && this.showLayer.indexOf(number) !== -1) {
            map.add(marker);
          }
        }
      }
    },
    // 海量点
    setMassivePoint(data, photoUrl, number, zIndex = 111, show = false) {
      const that = this;
      let map = amapManager.getMap();
      // 判断地图实例是否可以获取得到，否则两秒后重新执行本函数获取到地图实例后再进行海量点渲染
      if (map === null || map === undefined) {
        setTimeout(function () {
          that.setMassivePoint(data, photoUrl, number, zIndex, show);
        }, 2000);
      } else {
        let style = [
          {
            url: photoUrl,
            anchor: new AMap.Pixel(16, 16),
            size: new AMap.Size(32, 32),
          },
        ];
        let mass = new AMap.MassMarks(data, {
          zIndex: zIndex,
          cursor: "pointer",
          style: style,
        });
        if (
          this.masskersObj[number] !== "" &&
          this.masskersObj[number] !== undefined
        ) {
          map.remove(this.masskersObj[number]);
        }
        this.masskersObj[number] = mass;
        mass.on("click", function (e) {
          e.data.events.click();
        });
        if (show) {
          this.masskersObj[number].setMap(map);
        }
      }
    },
    // 打开筛选面板
    openBar() {
      this.$refs.setup.open();
    },
    // 跳转飞马在线设备多屏界面
    feimaOnline() {
      window.open(process.env.VUE_APP_ONLINE);
    },
    // 在线设备弹框
    online() {
      this.onlineShow = !this.onlineShow;
    },
    // 历史设备弹框
    historyBtn() {
      this.historyShow = !this.historyShow;
    },
    switchMap(index){
      switch(index){
        case 1:
          this.guideMap();
          break
        case 2:
          this.skyMap()
          break
        case 3:
          this.imageTile();
          break
        default:
          break
      }
    },
    // 高德地图
    guideMap() {
      this.googleLayer.setMap(null);
      this.tiandituLayer.setMap(null);
      this.tianditutext.setMap(null);

      this.setGcjPosition();
      this.isGcjPosition = true;
      this.setLocationMarker();
      // 边界线
      this.gxPolygon.forEach((item) => {
        item.setMap(null);
      });
      this.handlePolygon(this.gxGcjPolygon);
    },
    // 天地图
    skyMap() {
      this.tiandituLayer.setMap(this.elMap);
      this.tianditutext.setMap(this.elMap);
      this.googleLayer.setMap(null);
      this.setWgsPosition();
      this.isGcjPosition = false;
      this.setLocationMarker();
      // this.gxPolygon.setMap(null)
      // 将高德地图gcj02坐标系的边界线去除
      this.gxPolygon.forEach((item) => {
        item.setMap(null);
      });
      // 第一次点击时要将坐标转换一下，第二次就不用了

      this.handlePolygon(this.gxWgsPolygon);
    },
    // 影像瓦片
    imageTile() {
      this.googleLayer.setMap(this.elMap);
      this.tiandituLayer.setMap(null);
      this.tianditutext.setMap(null);
      this.setGcjPosition();
      this.isGcjPosition = true;
      this.setLocationMarker();
      // 边界线
      this.gxPolygon.forEach((item) => {
        item.setMap(null);
      });
      this.handlePolygon(this.gxGcjPolygon);
    },
    // 使用gcj02坐标系渲染海量点及标记点
    setGcjPosition() {
      let center = this.elMap.getCenter();
      let resLnglat;
      let that = this;
      if (!this.isGcjPosition) {
        AMap.convertFrom(center, "gps", function (status, result) {
          if (result.info === "ok") {
            resLnglat = result.locations[0];
            that.elMap.setCenter(resLnglat);
          }
        });
      }
      for (let i in this.showLayer) {
        if (
          this.masskersObj[this.showLayer[i] + 6] !== undefined &&
          this.masskersObj[this.showLayer[i] + 6] !== ""
        ) {
          if (this.masskersObj[this.showLayer[i] + 6].length === undefined) {
            this.masskersObj[this.showLayer[i] + 6].setMap(null);
            this.masskersObj[this.showLayer[i]].setMap(this.elMap);
          } else {
            for (let j in this.masskersObj[this.showLayer[i] + 6]) {
              this.masskersObj[this.showLayer[i] + 6][j].setMap(null);
              this.masskersObj[this.showLayer[i]][j].setMap(this.elMap);
            }
          }
        }
      }
    },
    // 使用天地图坐标系渲染海量点及标记点
    setWgsPosition() {
      let center = this.elMap.getCenter();
      this.elMap.setCenter(this.gcj_decrypt_exact(center.lat, center.lng));
      for (let i in this.showLayer) {
        if (
          this.masskersObj[this.showLayer[i] + 6] !== undefined &&
          this.masskersObj[this.showLayer[i] + 6] !== ""
        ) {
          if (this.masskersObj[this.showLayer[i] + 6].length === undefined) {
            this.masskersObj[this.showLayer[i] + 6].setMap(this.elMap);
            this.masskersObj[this.showLayer[i]].setMap(null);
          } else {
            for (let j in this.masskersObj[this.showLayer[i] + 6]) {
              this.masskersObj[this.showLayer[i] + 6][j].setMap(this.elMap);
              this.masskersObj[this.showLayer[i]][j].setMap(null);
            }
          }
        }
      }
    },
    /**
     * 高德坐标系转为天地图坐标系
     */
    transformLat(x, y) {
      let ret =
        -100.0 +
        2.0 * x +
        3.0 * y +
        0.2 * y * y +
        0.1 * x * y +
        0.2 * Math.sqrt(Math.abs(x));
      ret +=
        ((20.0 * Math.sin(6.0 * x * this.PI) +
          20.0 * Math.sin(2.0 * x * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin((y / 3.0) * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((160.0 * Math.sin((y / 12.0) * this.PI) +
          320 * Math.sin((y * this.PI) / 30.0)) *
          2.0) /
        3.0;
      return ret;
    },
    transformLon(x, y) {
      let ret =
        300.0 +
        x +
        2.0 * y +
        0.1 * x * x +
        0.1 * x * y +
        0.1 * Math.sqrt(Math.abs(x));
      ret +=
        ((20.0 * Math.sin(6.0 * x * this.PI) +
          20.0 * Math.sin(2.0 * x * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin((x / 3.0) * this.PI)) *
          2.0) /
        3.0;
      ret +=
        ((150.0 * Math.sin((x / 12.0) * this.PI) +
          300.0 * Math.sin((x / 30.0) * this.PI)) *
          2.0) /
        3.0;
      return ret;
    },
    gcj_encrypt(wgsLat, wgsLon) {
      if (this.outOfChina(wgsLat, wgsLon)) return { lat: wgsLat, lon: wgsLon };
      let d = this.delta(wgsLat, wgsLon);
      return { lat: wgsLat + d.lat, lon: wgsLon + d.lon };
    },
    outOfChina(lat, lon) {
      if (lon < 72.004 || lon > 137.8347) return true;
      if (lat < 0.8293 || lat > 55.8271) return true;
      return false;
    },
    // 转坐标系主要函数
    gcj_decrypt_exact(gcjLat, gcjLon) {
      let initDelta = 0.01;
      let threshold = 0.000000001;
      let dLat = initDelta,
        dLon = initDelta;
      let mLat = gcjLat - dLat,
        mLon = gcjLon - dLon;
      let pLat = gcjLat + dLat,
        pLon = gcjLon + dLon;
      let wgsLat,
        wgsLon,
        i = 0;
      while (1) {
        wgsLat = (mLat + pLat) / 2;
        wgsLon = (mLon + pLon) / 2;
        let tmp = this.gcj_encrypt(wgsLat, wgsLon);
        dLat = tmp.lat - gcjLat;
        dLon = tmp.lon - gcjLon;
        if (Math.abs(dLat) < threshold && Math.abs(dLon) < threshold) break;
        if (dLat > 0) pLat = wgsLat;
        else mLat = wgsLat;
        if (dLon > 0) pLon = wgsLon;
        else mLon = wgsLon;
        if (++i > 10000) break;
      }
      return [wgsLon, wgsLat];
    },

    // 监控飞马在线架次
    monitorFeiMaOnlineFlight() {
      this.$apis
        .getFeiMaLocation({}, this.token)
        .then((res) => {
          let result = res.data.data;
          this.feimaOnlineCount = result.length;
          for (let i = 0; i < result.length; i++) {
            let element = result[i];
            let point = coordtransform.wgs84togcj02(
              Number(element.home_lng),
              Number(element.home_lat)
            );
            let events = {
              click: (e) => {
                // 打开新页面
                // window.open(element.iframe_url);
                // ToDo 打开弹窗
                this.getDroneInfo(element, "feima", false, "wgsOnlineFeima");
              },
            };
            let obj = {
              position: point,
              flight_mission_id: element.flight_mission_id,
              events: events,
              style: 1,
            };
            let wgsObj = {
              position: [Number(element.home_lng), Number(element.home_lat)],
              flight_mission_id: element.flight_mission_id,
              events: events,
              style: 1,
            };
            let findIndex = this.onlineFeima.findIndex((v) => {
              return v.flight_mission_id == element.flight_mission_id;
            });
            //不存在则加入
            if (findIndex < 0) {
              this.onlineFeima.push(obj);
              this.wgsOnlineFeima.push(wgsObj);
            }
          }

          //删除消失的飞马图标
          for (let i = 0; i < this.onlineFeima.length; i++) {
            let findIndex = result.findIndex((v) => {
              return (
                v.flight_mission_id == this.onlineFeima[i].flight_mission_id
              );
            });
            if (findIndex < 0) {
              this.onlineFeima.splice(i, 1);
              this.wgsOnlineFeima.splice(i, 1);
            }
          }

          if (
            JSON.stringify(this.tem_onlineFeima) !==
            JSON.stringify(this.onlineFeima)
          ) {
            this.tem_onlineFeima = JSON.parse(JSON.stringify(this.onlineFeima));
            if (this.monitorType == "list") {
              if (this.isGcjPosition) {
                this.setOnlineMakers(
                  this.onlineFeima,
                  require("@/assets/svg/monitor_feima.svg"),
                  0
                );
                this.setOnlineMakers(
                  this.wgsOnlineFeima,
                  require("@/assets/svg/monitor_feima.svg"),
                  6,
                  100,
                  false
                );
              } else {
                this.setOnlineMakers(
                  this.onlineFeima,
                  require("@/assets/svg/monitor_feima.svg"),
                  0,
                  100,
                  false
                );
                this.setOnlineMakers(
                  this.wgsOnlineFeima,
                  require("@/assets/svg/monitor_feima.svg"),
                  6
                );
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 监控飞马历史架次
    async getFeiMaHistoryFlight(
      created_at_from = null,
      created_at_to = null,
      showHistory = false
    ) {
      this.historyFeima = [];
      this.wgsOnlineFeima = [];
      let lastPage = await this.getFeimaHistoryFlightData(
        1,
        created_at_from,
        created_at_to,
        showHistory
      );
      let page = 1;
      for (let i = 2; i <= lastPage; i++) {
        page++;
        await this.getFeimaHistoryFlightData(
          page,
          created_at_from,
          created_at_to,
          showHistory
        );
      }
    },
    // 获取飞马历史架次数据
    getFeimaHistoryFlightData(
      page,
      created_at_from = null,
      created_at_to = null,
      showHistory = false
    ) {
      return new Promise((resolve, reject) => {
        let param = { page: page };
        if (created_at_from != null) {
          param.created_at_from = created_at_from;
        }
        if (created_at_to != null) {
          param.created_at_to = created_at_to;
        }
        this.$apis
          .getFeiMaHistoryFlight(param, this.token)
          .then((data) => {
            for (let i = 0; i < data.data.data.data.length; i++) {
              const element = data.data.data.data[i];
              let obj = {
                lnglat: coordtransform.wgs84togcj02(
                  Number(element.home_lng),
                  Number(element.home_lat)
                ),
                style: 1,
                events: {
                  click: (e) => {
                    // 打开新页面
                    // window.open(element.iframe_url);
                    // ToDo 打开弹窗
                    //在线设备弹框
                    element["nowloaction"] = [
                      Number(element.lon),
                      Number(element.lat),
                    ];
                    this.getDroneInfo(
                      element,
                      "feima",
                      false,
                      "wgsHistoryFeima"
                    );
                  },
                },
              };
              let wgsObj = {
                lnglat: [Number(element.home_lng),Number(element.home_lat)],
                style: 1,
                events: {
                  click: (e) => {
                    // 打开新页面
                    // window.open(element.iframe_url);
                    // ToDo 打开弹窗
                    element["nowloaction"] = [
                      Number(element.lon),
                      Number(element.lat),
                    ];

                    this.getDroneInfo(
                      element,
                      "feima",
                      false,
                      "wgsHistoryFeima"
                    );
                  },
                },
              };
              this.historyFeima.push(obj);
              this.wgsHistoryFeima.push(wgsObj);
            }
            //时间筛选的架次数量
            this.feimaHistoryFliterCount = data.data.data.total;
            //总的历史架次数量
            this.feimaHistoryCount = data.data.data.history_flight_all_num;

            resolve(data.data.data.last_page);
            if (this.tem_historyFeima !== this.historyFeima) {
              this.tem_historyFeima = JSON.parse(
                JSON.stringify(this.historyFeima)
              );
              if (this.isGcjPosition) {
                this.setMassivePoint(
                  this.historyFeima,
                  require("@/assets/img/home／feima／off.png"),
                  1,
                  111,
                  showHistory
                );
                this.setMassivePoint(
                  this.wgsHistoryFeima,
                  require("@/assets/img/home／feima／off.png"),
                  7,
                  111,
                  false
                );
              } else {
                this.setMassivePoint(
                  this.historyFeima,
                  require("@/assets/img/home／feima／off.png"),
                  1,
                  111,
                  false
                );
                this.setMassivePoint(
                  this.wgsHistoryFeima,
                  require("@/assets/img/home／feima／off.png"),
                  7,
                  111,
                  showHistory
                );
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },

    // 监控通用在线架次
    monitorCommonOnlineFlight() {
      this.$apis.getCommonPlane({ type: "online" }, this.token).then((res) => {
        let result = res.data.data.online_common_plane;
        this.commonOnlineCount = result.length;
        // 添加飞机坐标
        for (let i = 0; i < result.length; i++) {
          let element = result[i];
          let point = coordtransform.wgs84togcj02(
            Number(element.lon),
            Number(element.lat)
          );
          let obj = {
            position: point,
            style: 1,
            UASID: element.UASID,
            events: {
              click: (e) => {
                // 打开新页面
                // window.open(element.url);
                // ToDo 打开弹窗
                element["nowloaction"] = [
                  Number(element.lon),
                  Number(element.lat),
                ];
                this.getDroneInfo(element, "common", false, "wgsOnlineCommon");
              },
            },
          };
          let wgsObj = {
            position: [Number(element.lon), Number(element.lat)],
            style: 1,
            UASID: element.UASID,
            events: {
              click: (e) => {
                // 打开新页面
                // window.open(element.url);
                // ToDo 打开弹窗
                element["nowloaction"] = [
                  Number(element.lon),
                  Number(element.lat),
                ];
                this.getDroneInfo(element, "common", false, "wgsOnlineCommon");
              },
            },
          };
          let findIndex = this.onlineCommon.findIndex((v) => {
            return v.UASID == element.UASID;
          });
          //不存在则加入
          if (findIndex < 0) {
            this.onlineCommon.push(obj);
            this.wgsOnlineCommon.push(wgsObj);
          }
        }
        //删除消失的通用架次图标

        for (let i = 0; i < this.onlineCommon.length; i++) {
          let findIndex = result.findIndex((v) => {
            return v.UASID == this.onlineCommon[i].UASID;
          });
          if (findIndex < 0) {
            this.onlineCommon.splice(i, 1);
            this.wgsOnlineCommon.splice(i, 1);
          }
        }
      });
      if (
        JSON.stringify(this.tem_onlineCommon) !==
        JSON.stringify(this.onlineCommon)
      ) {
        this.tem_onlineCommon = JSON.parse(JSON.stringify(this.onlineCommon));
        if (this.monitorType == "list") {
          if (this.isGcjPosition) {
            this.setOnlineMakers(
              this.onlineCommon,
              require("@/assets/svg/monitor_common.svg"),
              2
            );
            this.setOnlineMakers(
              this.wgsOnlineCommon,
              require("@/assets/svg/monitor_common.svg"),
              8,
              100,
              false
            );
          } else {
            this.setOnlineMakers(
              this.onlineCommon,
              require("@/assets/svg/monitor_common.svg"),
              2,
              100,
              false
            );
            this.setOnlineMakers(
              this.wgsOnlineCommon,
              require("@/assets/svg/monitor_common.svg"),
              8
            );
          }
        }
      }
    },
    // 获取通用历史架次
    async getCommonHistoryFlight(
      created_at_from = null,
      created_at_to = null,
      showHistory = false
    ) {
      this.historyCommon = [];
      this.wgsHistoryCommon = [];
      let lastPage = await this.getCommonHistoryFlightData(
        1,
        created_at_from,
        created_at_to,
        showHistory
      );
      let page = 1;
      for (let i = 2; i <= lastPage; i++) {
        page++;
        await this.getCommonHistoryFlightData(
          page,
          created_at_from,
          created_at_to,
          showHistory
        );
      }
    },
    // 获取通用历史架次数据
    getCommonHistoryFlightData(
      page,
      created_at_from = null,
      created_at_to = null,
      showHistory = false
    ) {
      return new Promise((resolve, reject) => {
        let param = { page: page, type: "history", per_page: 500 };
        if (created_at_from != null) {
          param.created_at_from = created_at_from;
        }
        if (created_at_to != null) {
          param.created_at_to = created_at_to;
        }
        this.$apis
          .getCommonPlane(param, this.token)
          .then((res) => {
            let result = res.data.data.history_common_plane.data;
            for (let i = 0; i < result.length; i++) {
              const element = result[i];
              let obj = {
                lnglat: coordtransform.wgs84togcj02(
                  Number(element.lon),
                  Number(element.lat)
                ),
                style: 1,
                events: {
                  click: (e) => {
                    // 打开新页面
                    // window.open(element.url);
                    // ToDo 打开弹窗
                    element["nowloaction"] = [
                      Number(element.lon),
                      Number(element.lat),
                    ];
                    this.getDroneInfo(
                      element,
                      "common",
                      true,
                      "wgsHistoryCommon"
                    );
                  },
                },
              };
              let wgsObj = {
                lnglat: [Number(element.lon), Number(element.lat)],
                style: 1,
                events: {
                  click: (e) => {
                    // 打开新页面
                    // window.open(element.url);
                    // ToDo 打开弹窗
                    element["nowloaction"] = [
                      Number(element.lon),
                      Number(element.lat),
                    ];
                    this.getDroneInfo(
                      element,
                      "common",
                      true,
                      "wgsHistoryCommon"
                    );
                  },
                },
              };
              this.historyCommon.push(obj);
              this.wgsHistoryCommon.push(wgsObj);
            }
            //时间筛选的架次数量
            this.commonHistoryFliterCount =
              res.data.data.history_common_plane.total;
            resolve(res.data.data.history_common_plane.last_page);
            if (this.tem_historyCommon !== this.historyCommon) {
              this.tem_historyCommon = JSON.parse(
                JSON.stringify(this.historyCommon)
              );
              if (this.isGcjPosition) {
                this.setMassivePoint(
                  this.historyCommon,
                  require("@/assets/img/home／general／off.png"),
                  3,
                  111,
                  showHistory
                );
                this.setMassivePoint(
                  this.wgsHistoryCommon,
                  require("@/assets/img/home／general／off.png"),
                  9,
                  111,
                  false
                );
              } else {
                this.setMassivePoint(
                  this.historyCommon,
                  require("@/assets/img/home／general／off.png"),
                  3,
                  111,
                  false
                );
                this.setMassivePoint(
                  this.wgsHistoryCommon,
                  require("@/assets/img/home／general／off.png"),
                  9,
                  111,
                  showHistory
                );
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },

    //监控在线终端
    async monitorOnlineTerminal() {
      let showTrack = this.$refs.setup.onlineTerminalTrackVisible;
      let isShowTrack = showTrack ? 1 : 0;
      let data = await this.getTerminal("online", isShowTrack);
      this.terminalOnlineCount = data.online_terminal;

      let arr = data.online_terminal_coordinate;
      for (let i = 0; i < arr.length; i++) {
        let randomData = Number(
          (Math.random() * (0.0000009 - 0.0000001) + 0.00000001).toFixed(7)
        );
        let arrStr = [];
        let pointList = arr[i].location.split(";");
        //加载最后坐标的图标
        let lastPoint = pointList[0].split(",");

        let track = {
          path: arrStr,
          visible: showTrack,
        };
        //加载轨迹
        arrStr.push(pointList);
        for (let j = 0; j < arrStr.length; j++) {
          const element = arrStr[j];
          element.pop();
          for (let k = 0; k < element.length; k++) {
            if (typeof element[k] !== "string") {
              continue;
            }
            const ele = element[k].split(",");
            if (Number(ele[0]) !== NaN || Number(ele[1]) !== NaN) {
              element[k] = [Number(ele[1]), Number(ele[0])];
              // element[k] = this.gcj_decrypt_exact(Number(ele[0]),Number(ele[1]))
            }
          }
          arrStr[j] = element;
        }
        // wgs坐标的轨迹
        let wgsArrStr = [];
        let wgsPointList = arr[i].location.split(";");
        //加载最后坐标的图标
        let wgsLastPoint = wgsPointList[0].split(",");
        let wgsTrack = {
          path: wgsArrStr,
          visible: showTrack,
        };
        //加载轨迹
        wgsArrStr.push(wgsPointList);
        for (let j = 0; j < wgsArrStr.length; j++) {
          const wgselement = wgsArrStr[j];
          wgselement.pop();
          for (let k = 0; k < wgselement.length; k++) {
            if (typeof wgselement[k] !== "string") {
              continue;
            }
            const wgsele = wgselement[k].split(",");
            if (Number(wgsele[0]) !== NaN || Number(wgsele[1]) !== NaN) {
              // wgselement[k] = [Number(ele[1]), Number(ele[0])];
              wgselement[k] = this.gcj_decrypt_exact(
                Number(wgsele[0]) + randomData,
                Number(wgsele[1]) + randomData
              );
            }
          }
          wgsArrStr[j] = wgselement;
        }
        let markerObj = {
          position: [
            Number(lastPoint[1]) + randomData,
            Number(lastPoint[0]) + randomData,
          ],
        };
        let wgsPosition = this.gcj_decrypt_exact(
          Number(lastPoint[0]) + randomData,
          Number(lastPoint[1]) + randomData
        );
        let events = {
          click: (e) => {
            //在线设备弹框
            let obj = arr[i];
            obj.track = track;
            obj.nowloaction = [Number(lastPoint[1]), Number(lastPoint[0])];
            this.getDeviceDetailInfo(obj);
          },
        };
        let findIndex = this.onlineTerminal.findIndex((v) => {
          return v.uuid == arr[i].uuid;
        });
        // //不存在则加入
        if (findIndex < 0) {
          this.onlineTerminal.push({
            uuid: arr[i].uuid,
            position: [
              Number(lastPoint[1]) + randomData,
              Number(lastPoint[0]) + randomData,
            ],
            style: 1,
            events: {
              click: (e) => {
                //在线设备弹框
                let obj = arr[i];
                obj.track = track;
                obj.nowloaction = [
                  Number(lastPoint[1]) + randomData,
                  Number(lastPoint[0]) + randomData,
                ];
                obj.deviceLayer = "onlineTerminal";
                this.getDeviceDetailInfo(obj);
              },
            },
            track: track,
          });
          this.wgsOnlineTerminal.push({
            uuid: arr[i].uuid,
            position: wgsPosition,
            style: 1,
            events: {
              click: (e) => {
                //在线设备弹框
                let obj = arr[i];
                obj.track = track;
                obj.nowloaction = [Number(lastPoint[1]), Number(lastPoint[0])];
                obj.deviceLayer = "onlineTerminal";
                this.getDeviceDetailInfo(obj);
              },
            },
            track: wgsTrack,
          });
        } else {
          //存在则只更换轨迹和坐标
          // this.onlineTerminal[findIndex].marker.position = markerObj.position;
          this.onlineTerminal[findIndex].position = markerObj.position;
          this.onlineTerminal[findIndex].track.path = arrStr;
          this.wgsOnlineTerminal[findIndex].position = wgsPosition;
          this.wgsOnlineTerminal[findIndex].track.path = wgsArrStr;
        }
      }
      //删除消失的设备
      for (let i = 0; i < this.onlineTerminal.length; i++) {
        let findIndex = arr.findIndex((v) => {
          return v.uuid == this.onlineTerminal[i].uuid;
        });
        if (findIndex < 0) {
          this.onlineTerminal.splice(i, 1);
          this.wgsOnlineTerminal.splice(i, 1);
        }
      }
      if (
        JSON.stringify(this.tem_onlineTerminal) !==
        JSON.stringify(this.onlineTerminal)
      ) {
        this.tem_onlineTerminal = JSON.parse(
          JSON.stringify(this.onlineTerminal)
        );
        if (this.monitorType == "list") {
          if (this.isGcjPosition) {
            this.setOnlineMakers(
              this.onlineTerminal,
              require("@/assets/svg/monitor_terminal.svg"),
              4,
              100,
              true,
              true
            );
            this.setOnlineMakers(
              this.wgsOnlineTerminal,
              require("@/assets/svg/monitor_terminal.svg"),
              10,
              100,
              false,
              true
            );
          } else {
            this.setOnlineMakers(
              this.onlineTerminal,
              require("@/assets/svg/monitor_terminal.svg"),
              4,
              100,
              false,
              true
            );
            this.setOnlineMakers(
              this.wgsOnlineTerminal,
              require("@/assets/svg/monitor_terminal.svg"),
              10,
              100,
              true,
              true
            );
          }
        }
      }
    },
    //获取历史终端
    async getHistoryTerminal(
      showTrack = false,
      created_from = null,
      created_to = null,
      showHistory = false
    ) {
      let isShowTrack = this.$refs.setup.historyTerminalTrackVisible ? 1 : 0;
      let data = await this.getTerminal(
        "history",
        isShowTrack,
        created_from,
        created_to
      );
      //历史终端
      this.terminalHistoryCount = data.history_terminal;
      //历史通用
      this.commonHistoryCount = data.history_common;

      let arr = data.history_terminal_coordinate;
      this.terminalHistoryFliterCount = arr.length;
      this.historyTerminal = [];
      this.wgsHistoryTerminal = [];
      for (let i = 0; i < arr.length; i++) {
        let arrStr = [];
        let pointList = arr[i].location.split(";");
        let lastPoint = pointList[0].split(",");

        // 计算wgs坐标的轨迹点
        let wgsArrStr = [];
        let wgsPointList = arr[i].location.split(";");
        //加载最后坐标的图标
        let wgsLastPoint = wgsPointList[0].split(",");
        //加载轨迹
        arrStr.push(pointList);
        wgsArrStr.push(wgsPointList);
        for (let j = 0; j < arrStr.length; j++) {
          const element = arrStr[j];
          element.pop();

          const wgselement = wgsArrStr[j];
          wgselement.pop();
          for (let k = 0; k < element.length; k++) {
            if (typeof element[k] !== "string") {
              continue;
            }
            const ele = element[k].split(",");
            if (Number(ele[0]) !== NaN || Number(ele[1]) !== NaN) {
              element[k] = [Number(ele[1]), Number(ele[0])];
              wgselement[k] = this.gcj_decrypt_exact(
                Number(ele[0]),
                Number(ele[1])
              );
            }
          }
          arrStr[j] = element;
          wgsArrStr[j] = wgselement;
        }

        let track = {
          path: arrStr,
          visible: showTrack,
        };
        let wgsTrack = {
          path: wgsArrStr,
          visible: showTrack,
        };
        let gcjPosition = [Number(lastPoint[1]), Number(lastPoint[0])];
        let wcgPositon = this.gcj_decrypt_exact(
          Number(lastPoint[0]),
          Number(lastPoint[1])
        );
        let events = {
          click: (e) => {
            //在线设备弹框
            let obj = arr[i];
            obj.track = track;

            obj.nowloaction = gcjPosition;
            obj.deviceLayer = "historyTerminal";
            this.getDeviceDetailInfo(obj);
          },
        };
        this.historyTerminal.push({
          uuid: arr[i].uuid,
          lnglat: [Number(lastPoint[1]), Number(lastPoint[0])],
          events: events,
          style: 1,
          track: track,
        });
        this.wgsHistoryTerminal.push({
          uuid: arr[i].uuid,
          lnglat: wcgPositon,
          events: events,
          style: 1,
          track: wgsTrack,
        });
      }
      if (this.isGcjPosition) {
        this.setMassivePoint(
          this.historyTerminal,
          require("@/assets/img/home-phone-off.png"),
          5,
          111,
          showHistory
        );
        this.setMassivePoint(
          this.wgsHistoryTerminal,
          require("@/assets/img/home-phone-off.png"),
          11,
          111,
          false
        );
      } else {
        this.setMassivePoint(
          this.historyTerminal,
          require("@/assets/img/home-phone-off.png"),
          5,
          111,
          false
        );
        this.setMassivePoint(
          this.wgsHistoryTerminal,
          require("@/assets/img/home-phone-off.png"),
          11,
          111,
          showHistory
        );
      }
    },

    // 获取无人机信息
    getDroneInfo(drone, planeType = "feima", isHistory = false, deviceLayer) {
      const param = {
        ...drone,
        showEnterButton: true,
        software_code:
          drone.uas_serial_id || drone.uavNumber || drone.software_code,
        type: "drone",
        planeType: planeType,
        isHistory: isHistory,
        deviceLayer: deviceLayer,
        // OrderID: drone.OrderID
      };
      this.getDeviceDetailInfo(param);
    },
    // 获取终端详细信息
    getDeviceDetailInfo(param, open = true) {
      const uuid = param.uuid != null ? param.uuid : "";
      const software_code =
        param.software_code != null ? param.software_code : "";
      const droneInfo = param.type === "drone" && param;
      this.$apis
        .getDeviceDetailInfo(
          {
            uuid,
            software_code,
          },
          this.token
        )
        .then((data) => {
          if (
            JSON.stringify(data.data.data) === "[]" ||
            data.data.data == null
          ) {
            // 重置terminal, 避免报 undefined
            this.terminal = {
              device_data: {
                software_code: "",
              },
              user_data: {},
            };
          } else {
            this.terminal = data.data.data;
          }
          this.terminal.track =
            param.track == null ? { visible: false } : param.track;
          this.trackButtonValue = this.terminal.track.visible;
          this.isShowThisTrack = this.terminal.track.visible;
          droneInfo.type === "drone" &&
            ((this.terminal.dialogType = "drone"),
            (this.terminal.droneInfo = droneInfo));
          if (param.planeType !== undefined) {
            this.terminal.planeType = param.planeType;
            this.terminal.isHistory = param.isHistory;
          }
          this.terminal.nowloaction =
            param.nowloaction === undefined ? [] : param.nowloaction;

          this.terminal.deviceLayer = param.deviceLayer;
          setTimeout(() => {
            if (open && data.data.data.device_data !== undefined) {
              this.historyTerminaldialogVisible = true;
            } else {
              this.$message(data.data.message);
            }
            // this.$refs.dtupop.open();
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取终端
    getTerminal(type, track, created_from, created_to) {
      return new Promise((resolve, reject) => {
        this.$apis
          .getTerminal(
            {
              type: type,
              track: 1,
              created_at_from: created_from,
              created_at_to: created_to,
            },
            this.token
          )
          .then((data) => {
            if (data.data.status == 0) {
              resolve(data.data.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },

    //更改所有在线终端轨迹的显隐
    changeOnlineTermianalTrackVisible(bool) {
      this.onlineTerminal.forEach((item) => {
        item.track.visible = bool;
      });
      this.wgsOnlineTerminal.forEach((item) => {
        item.track.visible = bool;
      });
    },
    //更改历史飞马
    changeHistoryFeima(data) {
      this.getFeiMaHistoryFlight(data.start_datetime, data.end_datetime, true);
    },
    // 更改历史通用
    changeHistoryCommon(data) {
      this.getCommonHistoryFlight(data.start_datetime, data.end_datetime, true);
    },
    //更改历史终端
    changeHistoryTerminal(data) {
      this.getHistoryTerminal(
        data.track,
        data.start_datetime,
        data.end_datetime,
        true
      );
    },

    //获取所有设备坐标（临时版本）
    async getAllDevicePoints() {
      this.getTypeDevice(1);
      this.getTypeDevice(2);
      this.getTypeDevice(3);
    },
    //获取某一类型的设备坐标
    getTypeDevice(type) {
      let iconUrl = "";
      let number1 = 1;
      let number2 = 7;

      switch (type) {
        case 1:
          iconUrl = require("@/assets/img/home／feima／off.png");
          number1 = 1;
          number2 = 7;
          break;
        case 2:
          iconUrl = require("@/assets/img/home／general／off.png");
          number1 = 3;
          number2 = 9;
          break;
        case 3:
          iconUrl = require("@/assets/img/home-phone-off.png");
          number1 = 5;
          number2 = 11;
          break;
      }

      return new Promise((resolve, reject) => {
        let param = { type: type };
        this.$apis.getDeviceLocation(param, this.token).then((res) => {
          if (res.data.status == 0) {
            let data = res.data.data;
            let pointGCJ02List = [];
            let pointWGS84List = [];
            data.forEach((item) => {
              if (item.longitude != 0) {
                let element = item;
                let obj = {
                  lnglat: [
                    Number(element.longitude) +
                      (Math.random() * 10) / 100000000,
                    Number(element.latitude) + (Math.random() * 10) / 100000000,
                  ],
                  style: 1,
                  events: {
                    click: (e) => {
                      if (param.type === 1) {
                        this.getDroneInfo(element);
                      } else if (param.type === 2) {
                        this.getDroneInfo(element, "common", true);
                      } else {
                        let obj = element;
                        obj.nowloaction = [element.longitude, element.latitude];
                        this.getDeviceDetailInfo(obj);
                      }
                    },
                  },
                };

                let wgsObj = {
                  lnglat: this.gcj_decrypt_exact(
                    Number(element.latitude) + (Math.random() * 10) / 100000000,
                    Number(element.longitude) + (Math.random() * 10) / 100000000
                  ),
                  style: 1,
                  events: {
                    click: (e) => {
                      this.getDroneInfo(element);
                    },
                  },
                };
                pointGCJ02List.push(obj);
                pointWGS84List.push(wgsObj);
              }
            });
            if (this.monitorType == "point") {
              this.setMassivePoint(pointGCJ02List, iconUrl, number1, 111, true);
            }

            resolve();
          }
          reject();
        });
      });
    },

    //初始化
    init() {
      //直接硬重置，初始化整个地图，把所有参数、事件初始化
      setTimeout(() => {
        this.mapKey = !this.mapKey;
      }, 0);
      this.mapKey = !this.mapKey;

      if (this.timer) {
        clearInterval(this.timer);
      }
      this.tem_onlineFeima = [];
      this.tem_onlineCommon = [];
      this.tem_onlineTerminal = [];

      if (this.monitorType == "list") {
        let firstDayOfMonth = timeJs.getFirstDayOfMonth() + " 00:00:00";
        let lastDayOfMonth = timeJs.getLastDayOfMonth() + " 23:59:59";
        //获取飞马历史架次
        this.getFeiMaHistoryFlight(firstDayOfMonth, lastDayOfMonth);
        //获取通用历史架次
        this.getCommonHistoryFlight(firstDayOfMonth, lastDayOfMonth);
        //获取历史终端
        this.getHistoryTerminal(false, firstDayOfMonth, lastDayOfMonth);
        this.timer = setInterval(() => {
          //获取飞马在线架次
          this.monitorFeiMaOnlineFlight();
          //获取通用在线架次
          this.monitorCommonOnlineFlight();
          //获取在线终端
          this.monitorOnlineTerminal();
        }, 5000);
        this.setInitLocation();
      } else {
        this.getAllDevicePoints();
      }
    },
    // 显示轨迹
    showThisTrack() {
      this.terminal.track.visible = this.isShowThisTrack;
    },
    // 查看更多数据
    lookParams() {
      // this.$set(this.terminal,'dialogType', 'params')
      // this.terminal = this.terminal
      // console.log(this.terminal.dialogType);
      // this.$refs.dtupop.lookParams();
      this.$message("功能暂未开放");

      // this.terminalDialogTitle = ""
    },
    returnDronne() {
      this.terminal["dialogType"] = "drone";
      this.$refs.dtupop.returnDronne();
    },
    //定位到指定位置
    setLocationInMap(lnglat, deviceType = "drone", locationData) {
      this.historyTerminaldialogVisible = false;
      // console.log(this[locationData],lnglat);
      let temLnglat = lnglat;
      if (deviceType === "drone") {
        if (this.isGcjPosition) {
          temLnglat = coordtransform.wgs84togcj02(lnglat[0], lnglat[1]);
        }
      }
      let isMarker = false;
      if (deviceType === "drone") {
        this[locationData].forEach((item) => {
          if (item.lnglat !== undefined){
            if (item.lnglat.lng !== undefined) {
            console.log(item);
            if (
              item.lnglat.lng === lnglat[0] &&
              item.lnglat.lat === lnglat[1]
            ) {
              isMarker = true;
            }
          } else {
            if (
              item.lnglat[0] === Number(lnglat[0]) &&
              item.lnglat[1] === Number(lnglat[1])
            ) {
              isMarker = true;
            }
          }
          }else{
            if (
              item.position[0] === Number(lnglat[0]) &&
              item.position[1] === Number(lnglat[1])
            ) {
              isMarker = true;
            }
          }

        });
      }else {
        isMarker = true;
      }

      this.elMap.setZoomAndCenter(17, temLnglat);
      if (this.deviceMarker !== "") {
        this.deviceMarker.setMap(null);
      }
      if (!isMarker) {
        let icon = new AMap.Icon({
            size: new AMap.Size(20, 30),    // 图标尺寸
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',  // Icon的图像
            // imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(20, 30)   // 根据所设置的大小拉伸或压缩图片
        });
        this.deviceMarker = new AMap.Marker({
          icon: icon,
          map: this.elMap,
          position: temLnglat,
          size: new AMap.Size(40, 50),
        });
      }
    },
  },
  mounted() {},
  watch: {
    terminalOnlineCount() {
      this.$emit("updateMonitor");
    },
  },
  computed: {
    ...mapState(["user", "token"]),

    //总历史设备
    historyCount() {
      return (
        this.feimaHistoryCount +
        this.commonHistoryCount +
        this.terminalHistoryCount
      );
    },
    //总在线设备
    onlineCount() {
      return (
        this.feimaOnlineCount +
        this.commonOnlineCount +
        this.terminalOnlineCount
      );
    },
    terminalDialogTitle() {
      if (this.monitorType == "point") {
        return "设备信息";
      }
      return this.terminal.dialogType === "drone" ? "任务信息" : "设备信息";
    },
  },
  // 移除事件监听，避免内存泄漏
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.particulars {
  width: 336px;
  position: absolute;
  z-index: 9999;
  top: 30%;
  left: 40%;
}
.dialogTitle {
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  background: #000620;
  font-size: 21px;
  color: #fff;
}
.historyTerminaldialog {
  .dialog-header {
    position: relative;
    width: 100%;
    display: inline-block;
    .dia_title {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 10px;
      // line-height: 21px;
    }
    .dia_name {
      font-size: 12px;
      color: #69789e;
    }

    .trajectoryBar {
      align-self: flex-end;
      bottom: -2px;
      position: absolute;
      right: 50px;
      // margin: 60px 20px 40px 0;
      .trajectory {
        margin-right: 14px;
        font-size: 12px;
        color: #69789e;
      }
      button {
        border-radius: 20px;
        background-color: #0052f7;
        border-color: #1890ff;
        color: #ffffff;
        opacity: 0.7;
      }
      button:hover {
        opacity: 1;
      }
    }
  }
  /deep/.el-dialog__title {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
  /deep/.el-divider--horizontal {
    margin: 0 !important;
  }
  /deep/.el-dialog {
    max-height: 480px !important;
    width: 316px !important;
    height: 56px;
    background: #001125;
    border-radius: 2px;
  }
  /deep/.el-dialog__header {
    height: 56px;
    background: #000b18 !important;
    padding: 18px 0 0 32px;
    border: 2px solid rgba(10, 110, 247, 0.4);
    border-bottom: 1px solid rgba(10, 110, 247, 0.4);
  }
  /deep/.el-dialog__body {
    padding: 0;
    // height: 90%;
    background: #001125 !important;
    border: 2px solid rgba(10, 110, 247, 0.4);
    border-top: none;
  }
}

.container {
  height: 100%;
  z-index: 999;
}
.monitor-marker {
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -16px;
}
.backend-panel {
  .setup {
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: #030d32;
    right: 27%;
    top: 2%;
    z-index: 999;
    border-radius: 2px;
    background: url("~@/assets/img/home／show&hidden.png") center no-repeat;
    cursor: pointer;
  }
  .online {
    position: absolute;
    width: 96px;
    height: 70px;
    background-color: #0052f7;
    left: 138px;
    top: 1.5%;
    z-index: 999;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      color: #fff;
      font-size: 13px;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 5px;
    }
    .count {
      font-size: 24px;
      color: #fff;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      font-family: DINPro-Bold, DINPro;
    }
  }
  .history {
    position: absolute;
    width: 96px;
    height: 70px;
    background-color: #030d32;
    left: 246px;
    top: 1.5%;
    z-index: 999;
    border-radius: 4px;
    border: 1px solid #0052f7;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      color: #fff;
      font-size: 13px;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 5px;
    }
    .count {
      font-size: 24px;
      color: #fff;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      font-family: DINPro-Bold, DINPro;
    }
  }
  .onlineBox {
    position: absolute;
    width: 116px;
    height: 186px;
    background-color: #030d32;
    border: 1px solid #ffffff;
    border-radius: 4px;
    left: 7.2%;
    top: 10.5%;
    z-index: 1000;
    opacity: 0.8;
    .feima {
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
      float: left;
      cursor: pointer;
      .userIcon {
        width: 32px;
        height: 32px;
        float: left;
      }
      .feimaTit {
        width: 40%;
        float: left;
        margin-left: 5%;
        .usertype {
          width: 80%;
          float: right;
          color: #a6aab8;
          font-size: 12px;
        }
        .count {
          width: 60%;
          color: #19f98f;
          font-size: 18px;
        }
      }
    }
    .currency {
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
      float: left;
      .userIcon {
        width: 32px;
        height: 32px;
        float: left;
      }
      .feimaTit {
        width: 40%;
        float: left;
        margin-left: 5%;
        .usertype {
          width: 80%;
          float: right;
          color: #a6aab8;
          font-size: 12px;
        }
        .count {
          width: 60%;
          color: #49b2ff;
          font-size: 18px;
        }
      }
    }
    .terminal {
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
      float: left;
      .userIcon {
        width: 32px;
        height: 32px;
        float: left;
      }
      .feimaTit {
        width: 40%;
        float: left;
        margin-left: 5%;
        .usertype {
          width: 80%;
          float: right;
          color: #a6aab8;
          font-size: 12px;
        }
        .count {
          width: 60%;
          color: #fb4439;
          font-size: 18px;
        }
      }
    }
  }
  .historyBox {
    position: absolute;
    width: 116px;
    height: 186px;
    background-color: #030d32;
    border: 1px solid #ffffff;
    border-radius: 4px;
    left: 13%;
    top: 10.5%;
    z-index: 1000;
    opacity: 0.8;
    .feima {
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
      float: left;
      .userIcon {
        width: 32px;
        height: 32px;
        float: left;
      }
      .feimaTit {
        width: 40%;
        float: left;
        margin-left: 5%;
        .usertype {
          width: 80%;
          float: right;
          color: #a6aab8;
          font-size: 12px;
        }
        .count {
          width: 60%;
          color: #1789d1;
          font-size: 18px;
        }
      }
    }
    .currency {
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
      float: left;
      .userIcon {
        width: 32px;
        height: 32px;
        float: left;
      }
      .feimaTit {
        width: 40%;
        float: left;
        margin-left: 5%;
        .usertype {
          width: 80%;
          float: right;
          color: #a6aab8;
          font-size: 12px;
        }
        .count {
          width: 60%;
          color: #1789d1;
          font-size: 18px;
        }
      }
    }
    .terminal {
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
      float: left;
      .userIcon {
        width: 32px;
        height: 32px;
        float: left;
      }
      .feimaTit {
        width: 40%;
        float: left;
        margin-left: 5%;
        .usertype {
          width: 80%;
          float: right;
          color: #a6aab8;
          font-size: 12px;
        }
        .count {
          width: 60%;
          color: #1789d1;
          font-size: 18px;
        }
      }
    }
  }
}
.portal-panel {
  .online {
    z-index: 999;
    width: 112px;
    height: 105px;
    background: linear-gradient(
      134deg,
      rgba(0, 111, 255, 1) 0%,
      rgba(71, 65, 255, 1) 70%
    );
    border-radius: 14px;
    position: absolute;
    left: 41px;
    top: 98px;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .history {
    z-index: 999;
    width: 112px;
    height: 105px;
    background: linear-gradient(
      132deg,
      rgba(2, 121, 197, 1) 0%,
      rgba(1, 68, 147, 1) 70%
    );
    border-radius: 14px;
    position: absolute;
    left: 41px;
    top: 226px;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .flying {
    font-size: 30px;
    color: #ffffff;
    margin-left: 9px;
    margin-top: 10px;
  }
  .count {
    font-size: 20px;
    font-family: DINPro-Bold, DINPro;
    font-weight: bold;
    color: #fff;
    margin: 0;
    line-height: 26px;
    text-align: center;
  }

  .title {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: rgba(255, 255, 255, 1);
    line-height: 16px;
    margin: 0;
  }
  .bottBar {
    margin-bottom: 5px;
  }
  .setup {
    position: absolute;
    right: 42px;
    top: 82px;
    width: 40px;
    height: 40px;
    background-color: #014493;
    border-radius: 8px;
    z-index: 999;
    background: url("~@/assets/img/gatewayImg/首页-显隐.png") center no-repeat;
    cursor: pointer;
  }
  .historydeta {
    z-index: 998;
    position: absolute;
    top: 226px;
    left: 82px;
    width: 220px;
    height: 105px;
    border-radius: 14px;
    background-color: #000000;
    opacity: 0.5;
    display: grid;
    align-items: center;
    justify-content: end;
  }
  .onlinedeta {
    z-index: 998;
    position: absolute;
    top: 98px;
    left: 82px;
    width: 220px;
    height: 105px;
    border-radius: 14px;
    background-color: #000000;
    opacity: 0.5;
    display: grid;
    align-items: center;
    justify-content: end;
  }
  .line {
    color: #ffffff;
    z-index: 999;
    width: 150px;
  }
  .brand {
    color: #ffffff;
    font-size: 12px;
    float: left;
    margin-left: 15px;
  }
  .line:hover {
    height: 20px;
    cursor: pointer;
  }
  .feima {
    float: right;
    color: #32f793;
    font-size: 12px;
    margin-right: 10px;
    width: 25px;
  }
  .tongyong {
    float: right;
    color: #49b2ff;
    font-size: 12px;
    margin-right: 10px;
    width: 25px;
  }
  .yidong {
    float: right;
    color: #fe4230;
    font-size: 12px;
    margin-right: 10px;
    width: 25px;
  }
  .circelone {
    float: right;
    font-size: 12px;
    color: #32f793;
    margin-right: 10px;
  }
  .circeltwo {
    float: right;
    font-size: 12px;
    color: #49b2ff;
    margin-right: 10px;
  }
  .circelthree {
    float: right;
    font-size: 12px;
    color: #fe4230;
    margin-right: 10px;
  }
}
</style>
