import { render, staticRenderFns } from "./subcharacters.vue?vue&type=template&id=f45c106c&scoped=true"
import script from "./subcharacters.vue?vue&type=script&lang=js"
export * from "./subcharacters.vue?vue&type=script&lang=js"
import style0 from "./subcharacters.vue?vue&type=style&index=0&id=f45c106c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f45c106c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/xhddgl.afuav.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f45c106c')) {
      api.createRecord('f45c106c', component.options)
    } else {
      api.reload('f45c106c', component.options)
    }
    module.hot.accept("./subcharacters.vue?vue&type=template&id=f45c106c&scoped=true", function () {
      api.rerender('f45c106c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/subcharacters.vue"
export default component.exports