import { render, staticRenderFns } from "./uploadFile.vue?vue&type=template&id=ad459c30&scoped=true"
import script from "./uploadFile.vue?vue&type=script&lang=js"
export * from "./uploadFile.vue?vue&type=script&lang=js"
import style0 from "./uploadFile.vue?vue&type=style&index=0&id=ad459c30&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad459c30",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/xhddgl.afuav.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ad459c30')) {
      api.createRecord('ad459c30', component.options)
    } else {
      api.reload('ad459c30', component.options)
    }
    module.hot.accept("./uploadFile.vue?vue&type=template&id=ad459c30&scoped=true", function () {
      api.rerender('ad459c30', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/messageBox/uploadFile.vue"
export default component.exports